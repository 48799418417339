import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from 'axios';

import { MdArrowForward, MdArrowBack } from 'react-icons/md';
import { ReactComponent as RedoIcon } from '~assets/redo-icon.svg';

import history from '~/services/history';
import { SelectStyle } from '~/pages/_layouts/default/styles';
import Select from 'react-select';
import SelectComponent from '~/components/SelectComponent';
import { Button } from '@medway-npm/ds-medstar';
import Loader from '~/components/Loader';
import DatePicker from '~/components/DatePickerComponent';
import HeaderComponent from '~/components/HeaderComponent';
import SelectWithPagination from '~/components/SelectWithPagination';
import InputMuiLabel from '~/components/InputMuiLabel';
import RadioButton from '~/components/RadioButton';
import GreenBorder from '~/components/GreenBorder';
import CheckboxDS from '~/components/CheckboxDS';
import { loadTags } from '~/pages/Chapters/components/Settings/utils';

import { InstitutionAPICMS, TaskAPICMS } from '~/services/apiCallsCMS';

import {
  useGetTypeActivity,
  useGetActivity,
  useGetFormField,
  useGetActivityById,
  useGetTypeActivityById,
  useListAnswersForm,
} from '~/hooks-querys/activity';
import { useSpecialityById } from '~/hooks-querys/speciality';
import { useTagById } from '~/hooks-querys/tag';
import { useFilterSpecialitys, useInstitutionById } from '~/hooks-querys/filters';
import { useResidencyDegree, useResidencyDegreeById } from '~/hooks-querys/residencydegree';
import { useGetDefaultUserById } from '~/hooks-querys/users';
import { useGetTaskById } from '~/hooks-querys/tasks';

import { MULTIPLIER } from '~pages/Financial/variables';
import ModalDataPartner from '../components/DataPartner';
import FormField from '../components/FormField';
import CreateActivity from '../components/CreateActivity';

import { loadDefaultUsers } from './utils';

import * as S from './styles';

function ConfigTask() {
  const { id } = useParams();
  const [nameTask, setNameTask] = useState(null);
  const [linkInstructions, setLinkInstructions] = useState(null);
  const [typeActivities, setTypeActivities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [residencyDegrees, setResidencyDegrees] = useState([]);
  const [selectedTypeActivity, setSelectedTypeActitvity] = useState(null);
  const [selectedResidencyDegree, setSelectedResidencyDegree] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [priceOriginal, setPriceOriginal] = useState(null);
  const [priceOverride, setPriceOverride] = useState(null);
  const [priceOverrideFloat, setPriceOverrideFloat] = useState(0);
  const [partnerOption, setPartnerOption] = useState('a');
  const [responsible, setResponsible] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalDataPartner, setShowModalDataPartner] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [noSpeciality, setNoSpeciality] = useState(false);
  const multiplierOptions = MULTIPLIER.map((element, index) => {
    return { label: element.label, value: element.value, key: index };
  });
  const [responsibleMultiplier, setResponsibleMultiplier] = useState(multiplierOptions[0]);

  const { data: dataTypeActivities } = useGetTypeActivity(
    {
      no_page: true,
      type_activity: selectedTypeActivity,
    },
    {
      config: { enabled: typeActivities.length === 0 },
    },
  );

  const { data: dataActivities, refetch: refetchActivity } = useGetActivity(
    {
      no_page: true,
      type_activity: selectedTypeActivity?.value,
    },
    { config: { enabled: !!selectedTypeActivity } },
  );

  const { data: dataSpecialities } = useFilterSpecialitys(
    {
      no_page: true,
      main_speciality: true,
    },
    { config: { enabled: specialities.length === 0 } },
  );

  const { data: dataResidencyDegrees } = useResidencyDegree(
    {
      no_page: true,
    },
    { config: { enabled: residencyDegrees.length === 0 } },
  );

  const { data: dataFormFields } = useGetFormField(
    {
      no_page: true,
      type_activity: selectedTypeActivity?.value,
    },
    { config: { enabled: !!selectedTypeActivity } },
  );

  const { data: updateTask, loading: loadingUpdateTask } = useGetTaskById(id, {
    config: { enabled: !!id },
  });

  const { data: speciality } = useSpecialityById(updateTask?.speciality_id, {
    config: {
      enabled: !!updateTask?.speciality_id,
    },
  });

  const { data: residencyDegree } = useResidencyDegreeById(updateTask?.residency_degree_id, {
    config: {
      enabled: !!updateTask?.residency_degree_id,
    },
  });

  const { data: tag } = useTagById(updateTask?.tag_id, {
    config: {
      enabled: !!updateTask?.tag_id,
    },
  });

  const { data: institution } = useInstitutionById(updateTask?.institution_id, {
    config: {
      enabled: !!updateTask?.institution_id,
    },
  });

  const { data: activity } = useGetActivityById(updateTask?.activity, {
    config: {
      enabled: !!updateTask?.activity,
    },
  });

  const { data: typeActivity } = useGetTypeActivityById(activity?.type_activity, {
    config: {
      enabled: !!activity?.type_activity,
    },
  });

  const { data: user } = useGetDefaultUserById(updateTask?.responsible, {
    config: {
      enabled: !!updateTask?.responsible,
    },
  });

  const { data: answersUpdateTask } = useListAnswersForm(
    { task: updateTask?.id },
    {
      config: {
        enabled: !!updateTask?.id,
      },
    },
  );

  const transformOptions = (institution) => {
    if (!institution.id || !institution.name) return;

    return {
      label: institution.state ? `${institution.name} - ${institution.state}` : institution.name,
      key: institution.id,
      value: institution.id,
      institution,
    };
  };

  const loadInstitutions = useCallback(async (search, loadedOptions, { page }) => {
    const { data } = await InstitutionAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });

    return {
      options: data.results.map(transformOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  useEffect(() => {
    if (!dataTypeActivities) return;

    setTypeActivities(
      dataTypeActivities.map((element) => {
        return { label: element.name, value: element.id, key: element.id };
      }),
    );
  }, [dataTypeActivities]);

  useEffect(() => {
    if (!typeActivities || !typeActivity) return;

    const matchedTypeActivity = typeActivities.find((item) => item.value === typeActivity.id);

    if (matchedTypeActivity) {
      setSelectedTypeActitvity(matchedTypeActivity);
    }
  }, [typeActivities, typeActivity]);

  useEffect(() => {
    if (!activities || !activity) return;

    const matchedActivity = activities.find((item) => item.value === activity.id);
    if (matchedActivity) {
      setSelectedActivity(matchedActivity);
      setPriceOriginal(activity.price);
    }
  }, [activities, activity]);

  useEffect(() => {
    if (!residencyDegrees || !residencyDegree) return;

    const matchedResidency = residencyDegrees.find((item) => item.value === residencyDegree.id);
    if (matchedResidency) {
      setSelectedResidencyDegree(matchedResidency);
    }
  }, [residencyDegrees, residencyDegree]);

  useEffect(() => {
    if (!specialities || !speciality) return;

    const matchedSpeciality = specialities.find((item) => item.value === speciality.id);
    if (matchedSpeciality) {
      setSelectedSpeciality(matchedSpeciality);
    }
  }, [specialities, speciality]);

  useEffect(() => {
    if (!dataActivities) return;

    setActivities(
      dataActivities.map((element) => {
        return { label: element.name, value: element.id, key: element.id, item: element };
      }),
    );
  }, [dataActivities]);

  useEffect(() => {
    if (!dataSpecialities) return;

    setSpecialities(
      dataSpecialities.map((element) => {
        return { label: element.name, value: element.id, key: element.id };
      }),
    );
  }, [dataSpecialities]);

  useEffect(() => {
    if (!dataResidencyDegrees) return;

    setResidencyDegrees(
      dataResidencyDegrees.map((element) => {
        return { label: element.name, value: element.id, key: element.id };
      }),
    );
  }, [dataResidencyDegrees]);

  useEffect(() => {
    if (!answersUpdateTask?.results) return;

    setAnswers(
      answersUpdateTask?.results.map((element) => {
        return {
          form_field: element.form_field,
          ...(element.object_id && { answer_id: element.object_id }),
          ...(element.answer && { answer_text: element.answer }),
        };
      }),
    );
  }, [answersUpdateTask]);

  useEffect(() => {
    if (!institution) return;

    setSelectedInstitution({
      key: institution.id,
      label: institution.name,
      value: institution.id,
    });
  }, [institution]);

  useEffect(() => {
    if (!user) return;

    setResponsible({
      key: user.id,
      label: `${user.name} - ${user.email}`,
      value: user.id,
      name: user.name.split(' - ')[0],
      email: user.email,
      ...(user?.multiplier && { multiplier: user.multiplier }),
      ...(user?.speciality_id && { speciality: user.speciality_id }),
    });
  }, [user]);

  useEffect(() => {
    if (!tag) return;

    setSelectedTag({
      key: tag.id,
      label: tag.verbose,
      value: tag.id,
    });
  }, [tag]);

  const handleNameTask = (event) => {
    setNameTask(event.target.value);
  };

  const handleTypeActivity = (event) => {
    setSelectedTypeActitvity(event);
  };

  const handleActivity = (event) => {
    setSelectedActivity(event);
    setPriceOriginal(event.item.price);
  };

  const handleLinkInstructions = (event) => {
    setLinkInstructions(event.target.value);
  };

  const handleSpeciality = (event) => {
    setSelectedSpeciality(event);
  };

  const handleResidencyDegree = (event) => {
    setSelectedResidencyDegree(event);
  };

  const handleNewPrice = (event, fromDb) => {
    let inputValue = 0;
    if (fromDb) {
      inputValue = event;
    } else {
      inputValue = event.target.value;
    }

    // Remove any non-numeric characters, except for commas and periods
    inputValue = inputValue.replace(/[^0-9]/g, '');

    // Convert input to a number and format as currency
    const numericValue = parseFloat(inputValue) / 100; // Convert to a number with two decimal places
    setPriceOverrideFloat(numericValue);
    const formattedValue = numericValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    setPriceOverride(formattedValue); // Update the state with the formatted value
  };

  useEffect(() => {
    if (!updateTask) return;
    setNameTask(updateTask.name);
    if (updateTask.is_super_external) {
      setPartnerOption('p');
    } else if (updateTask.responsible) {
      setPartnerOption('r');
    } else {
      setPartnerOption('a');
    }

    setLinkInstructions(updateTask.link_instructions);
    handleNewPrice((updateTask.price_override * 100).toString(), true);
    setDeadline(updateTask.deadline);
  }, [updateTask]);

  const validationForm = () => {
    if (!nameTask) {
      toast.error('Digite o nome da atividade');
      return;
    }

    if (!selectedTypeActivity) {
      toast.error('Selecione o tipo de atividade');
      return;
    }

    if (!selectedActivity) {
      toast.error('Selecione a atividade');
      return;
    }

    if (!linkInstructions) {
      toast.error('Digite o link das instruções');
      return;
    }

    if (!selectedResidencyDegree) {
      toast.error('Selecione o nível de residência');
      return;
    }

    if (!selectedSpeciality && !noSpeciality) {
      toast.error('Selecione a grande área');
      return;
    }

    if (partnerOption === 'r' && !responsible) {
      toast.error('Selecione o parceiro responsável');
      return;
    }

    if (!deadline) {
      toast.error('Selecione o prazo de entrega');
      return;
    }

    const data = {
      name: nameTask,
      type_activity: selectedTypeActivity?.value,
      activity: selectedActivity?.value,
      price_override: priceOverrideFloat,
      link_instructions: linkInstructions,
      deadline,
      responsible: responsible?.value,
      institution_id: selectedInstitution?.value,
      speciality_id: selectedSpeciality?.value,
      residency_degree_id: selectedResidencyDegree?.value,
      tag_id: selectedTag?.value,
      is_super_external: partnerOption === 'p',
      multiplier: responsibleMultiplier?.value,
    };

    const source = Axios.CancelToken.source();
    if (!updateTask) {
      TaskAPICMS.create(data, source.token)
        .then(async (result) => {
          const answerData = { answers: answers };
          TaskAPICMS.createAnswers(result.data.id, answerData).then(async (_result) => {
            toast.success('Atividade criada com sucesso');
            history.push({
              pathname: '/activities',
              state: {
                refetch: true,
              },
            });
          });
        })
        .catch(() => {
          toast.error(`Erro na criação da task.`);
        });
    } else {
      TaskAPICMS.update(updateTask.id, data, source.token)
        .then(async (result) => {
          const answerData = { answers: answers };
          TaskAPICMS.createAnswers(result.data.id, answerData).then(async (_result) => {
            toast.success('Atividade atualizada com sucesso');
            history.push({
              pathname: '/activities',
              state: {
                refetch: true,
              },
            });
          });
        })
        .catch(() => {
          toast.error(`Erro na criação da task.`);
        });
    }
  };

  const closeModalCreate = () => {
    setShowModalCreate(false);
    refetchActivity();
  };

  const closeModalDataPartner = () => {
    setShowModalDataPartner(false);
  };

  const getAnswer = (formFieldId) => {
    if (answersUpdateTask?.results?.length > 0) {
      const item = answersUpdateTask.results.find(
        (itemAnswer) => itemAnswer.form_field === formFieldId,
      );
      return item;
    } else return null;
  };

  const handleChangeMultiplier = (multiplier) => {
    setResponsibleMultiplier(multiplier);
  };

  const handleCheckboxSpeciality = (event) => {
    setNoSpeciality(event);
    setSelectedSpeciality(null);
  };

  useEffect(() => {
    if (!responsible?.multiplier) return;
    const multiplier = responsible?.multiplier.toString();
    if (responsible?.multiplier === 1) {
      return setResponsibleMultiplier(multiplierOptions[0]);
    }
    setResponsibleMultiplier(multiplierOptions.find((option) => option.value === multiplier));
  }, [responsible]);

  return (
    <>
      {id && loadingUpdateTask ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent title="Adicionar tarefa" />
          <S.Wrap>
            <S.WrapContainer>
              <S.ContainerNewTrack>
                <S.FormContainer>
                  <GreenBorder />
                  <S.StepContainer>
                    <S.Step>
                      <S.StepOder>1</S.StepOder>
                      <S.StepText>Configuração</S.StepText>
                    </S.Step>
                    <S.Container>
                      <S.Rows>Nome da tarefa</S.Rows>

                      <S.InputStyle>
                        <input
                          id="simulado"
                          type="text"
                          placeholder="Nome da tarefa"
                          onChange={handleNameTask}
                          value={nameTask || ''}
                        />
                      </S.InputStyle>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Tipo de atividade</S.Rows>

                      <S.SelectContainer>
                        <Select
                          name="type-activity"
                          placeholder="Selecione"
                          onChange={handleTypeActivity}
                          options={typeActivities}
                          styles={SelectStyle}
                          value={selectedTypeActivity}
                        />
                      </S.SelectContainer>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Atividade</S.Rows>
                      <S.RowContainer>
                        <S.SelectContainer>
                          <Select
                            name="activity"
                            placeholder="Selecione"
                            onChange={handleActivity}
                            options={activities}
                            styles={SelectStyle}
                            value={selectedActivity}
                          />
                        </S.SelectContainer>
                        <S.Button onClick={() => setShowModalCreate(true)}>Adicionar nova</S.Button>
                      </S.RowContainer>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Link para instruções</S.Rows>

                      <S.InputStyle>
                        <input
                          id="link-instructions"
                          type="text"
                          placeholder="Link para instruções"
                          onChange={handleLinkInstructions}
                          value={linkInstructions || ''}
                        />
                      </S.InputStyle>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Nível de Residência</S.Rows>
                      <S.SelectContainer>
                        <Select
                          name="residency"
                          placeholder="Selecione"
                          onChange={handleResidencyDegree}
                          options={residencyDegrees}
                          styles={SelectStyle}
                          value={selectedResidencyDegree}
                        />
                      </S.SelectContainer>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Grande área</S.Rows>

                      <S.RowContainer>
                        <S.SelectContainer>
                          <Select
                            name="speciality"
                            placeholder="Selecione"
                            onChange={handleSpeciality}
                            options={specialities}
                            styles={SelectStyle}
                            value={selectedSpeciality}
                            isDisabled={noSpeciality}
                          />
                        </S.SelectContainer>
                        <CheckboxDS
                          checked={noSpeciality}
                          onChange={handleCheckboxSpeciality}
                          label="Não se aplica"
                        />
                      </S.RowContainer>
                    </S.Container>

                    <S.Container>
                      <S.Rows>Instituição</S.Rows>
                      <S.RowMinor> Preencher somente se for aplicável</S.RowMinor>
                      <S.ContainerInstitution>
                        <SelectWithPagination
                          onChange={setSelectedInstitution}
                          loadOptions={loadInstitutions}
                          value={selectedInstitution}
                          name="institution"
                          backgroundColor="#f0f2f5"
                          height={48}
                          placeholder="Instituição"
                        />
                      </S.ContainerInstitution>
                    </S.Container>

                    <S.Container>
                      <S.Rows>Tema/Foco</S.Rows>
                      <S.RowMinor> Preencher somente se for aplicável</S.RowMinor>
                      <S.ContainerInstitution>
                        <SelectWithPagination
                          onChange={setSelectedTag}
                          loadOptions={loadTags}
                          value={selectedTag}
                          name="tag"
                          backgroundColor="#f0f2f5"
                          height={48}
                          placeholder="Tema/Foco"
                        />
                      </S.ContainerInstitution>
                    </S.Container>

                    <S.Container>
                      <S.Rows>Responsável</S.Rows>

                      <S.RadioContainer>
                        <RadioButton
                          control={partnerOption}
                          onChange={() => setPartnerOption('a')}
                          text="Aberto"
                          value="a"
                        />
                        <RadioButton
                          control={partnerOption}
                          onChange={() => setPartnerOption('p')}
                          text="Super Parceiro"
                          value="p"
                        />
                        <RadioButton
                          control={partnerOption}
                          onChange={() => setPartnerOption('r')}
                          text="Responsável"
                          value="r"
                        />
                        <S.SelectContainer width="30%">
                          <SelectWithPagination
                            disabled={partnerOption !== 'r'}
                            onChange={setResponsible}
                            loadOptions={loadDefaultUsers}
                            value={responsible}
                            name="partner"
                            backgroundColor="#f0f2f5"
                            height={48}
                            placeholder="Selecionar"
                            singleValueStyle={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '96%',
                            }}
                          />
                        </S.SelectContainer>
                      </S.RadioContainer>
                    </S.Container>
                    {responsible && (
                      <S.Container>
                        <S.RowButtonContainer>
                          <S.RowsText>Multiplicador</S.RowsText>
                          <Button
                            startIcon={<RedoIcon />}
                            onClick={() => setShowModalDataPartner(true)}
                          >
                            Atualizar dados do parceiro
                          </Button>
                        </S.RowButtonContainer>
                        <S.RowContainerInputs>
                          <S.SelectContainer width="35%" height="48px">
                            <InputMuiLabel
                              disabled
                              id="multiplier"
                              type="text"
                              value={responsibleMultiplier.label}
                              onChange={() => {
                                return;
                              }}
                            />
                          </S.SelectContainer>

                          <S.SelectContainerColumn width="35%" height="48px">
                            <SelectComponent
                              name="multiplier-override"
                              placeholder="Novo multiplicador"
                              onChange={handleChangeMultiplier}
                              options={multiplierOptions}
                              styles={SelectStyle}
                              value={responsibleMultiplier.label}
                              width="100%"
                              height={48}
                            />
                            <S.TipText>
                              Alterar o multiplicador somente para essa atividade
                            </S.TipText>
                          </S.SelectContainerColumn>
                        </S.RowContainerInputs>
                      </S.Container>
                    )}
                    <S.Container>
                      <S.Rows>Precificação</S.Rows>
                      <S.RowMinor>Caso tenha um budget diferente</S.RowMinor>
                      <S.RowContainerInputs>
                        <S.InputStyle width="35%">
                          <input
                            disabled
                            id="price"
                            type="text"
                            value={`R$ ${priceOriginal ? priceOriginal : '00'},00`}
                          />
                        </S.InputStyle>
                        <S.InputStyle width="35%">
                          <input
                            id="price-override"
                            type="text"
                            placeholder="Novo preço"
                            onChange={handleNewPrice}
                            value={priceOverride && priceOverride}
                          />
                        </S.InputStyle>
                      </S.RowContainerInputs>
                    </S.Container>
                    <S.Container>
                      <S.Rows>Prazo de entrega</S.Rows>
                      <S.RowContainerInputs>
                        <DatePicker
                          label="Início"
                          value={deadline}
                          handleChange={(event) => setDeadline(event)}
                        />
                      </S.RowContainerInputs>
                    </S.Container>
                  </S.StepContainer>
                  {dataFormFields?.length > 0 && (
                    <S.StepContainer>
                      <S.Step>
                        <S.StepOder>2</S.StepOder>
                        <S.StepText>Detalhes da Tarefa</S.StepText>
                      </S.Step>
                      {dataFormFields.map((formField) => {
                        return (
                          <FormField
                            formField={formField}
                            setAnswers={setAnswers}
                            answerUpdateTask={getAnswer(formField.id)}
                          />
                        );
                      })}
                    </S.StepContainer>
                  )}
                </S.FormContainer>
              </S.ContainerNewTrack>
            </S.WrapContainer>
            <S.FooterTrack>
              <S.ButtonBack type="button" onClick={() => history.push('/activities')}>
                <MdArrowBack size={18} color="#01c3aa" />
                Voltar
              </S.ButtonBack>
              <S.ButtonSave type="submit" onClick={() => validationForm()}>
                Salvar
                <MdArrowForward size={18} />
              </S.ButtonSave>
            </S.FooterTrack>
          </S.Wrap>
          {showModalCreate && (
            <CreateActivity showModal={showModalCreate} handleClose={() => closeModalCreate()} />
          )}
          {showModalDataPartner && (
            <ModalDataPartner
              showModal={showModalDataPartner}
              handleClose={() => closeModalDataPartner()}
              responsible={responsible}
              multiplierOptions={multiplierOptions}
              setResponsible={setResponsible}
            />
          )}
        </>
      )}
    </>
  );
}
export default ConfigTask;
