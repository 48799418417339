import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as ChatIcon } from '~/assets/chat-icon.svg';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';
import { RiErrorWarningLine } from 'react-icons/ri';
import NewEmptyContent from '~/components/NewEmptyContent';
import { StatusTag } from '../FilterDropdown/styles';
import SelectAllCheckbox from '../SelectAllCheckbox';
import { STATUS_OPTIONS, PARTNER_FORMS_LINK } from '../../variables';
import { formatPrice } from '~/utils/util';
import { AccordionStyle } from '../ListActivities/styles';
import { modifyNumberToFormat } from '../../utils';

import * as S from './styles';

function OrderPayments({
  orderPaymentsData,
  handleOrderChat,
  handleOrderOffcanvas,
  managePage,
  selectedStatus,
  selectedIds,
  setSelectedIds,
  fillForms,
  cancelOrder,
  orderError,
}) {
  const showCheckboxes = managePage && selectedStatus === 'f';
  const showOrderButtons = !managePage && selectedStatus === 'b';
  const showErrorButton = !managePage && selectedStatus === 'e';
  const showManageErrorButton = managePage && selectedStatus === 'f';
  const showCopyFormsLinkButton = !managePage && selectedStatus === 'f';
  const user = useSelector((state) => state.auth.user);

  const returnOrderValue = (value) => {
    if (!value) return 'R$ 0,00';
    const selectedValue = modifyNumberToFormat(value);

    return <b>{formatPrice(selectedValue)}</b>;
  };

  const returnFormattedPrice = (inputPrice) => {
    if (!inputPrice) return '-';
    const priceValue = modifyNumberToFormat(inputPrice);

    return formatPrice(priceValue);
  };

  const ReturnStatusTag = (tagStatus) => {
    const tag = STATUS_OPTIONS.find((status) => status.value === tagStatus);

    if (!tag) return '-';

    return (
      <StatusTag color={tag.color} background={tag.bgColor}>
        {tag.icon}
        {tag.label}
      </StatusTag>
    );
  };

  const returnMultiplier = (multiplier) => {
    if (!multiplier) return '-';
    return `${multiplier}x`;
  };

  const preventCollapse = (event) => {
    event.stopPropagation();
  };

  const formatDate = (dateString) => {
    if (!dateString) return;
    const dateWithSlash = dateString.replace('-', '/');
    return format(new Date(dateWithSlash), 'dd/MM/yyyy');
  };

  const showResponsibleButtons = (email) => {
    if (!managePage || !email || !user) return false;
    return user?.email === email;
  };

  return (
    <>
      {orderPaymentsData && orderPaymentsData.length === 0 ? (
        <NewEmptyContent
          title="Nenhuma ordem encontrada"
          subtitle="Altere os filtros e tente novamente"
        />
      ) : (
        <S.Wrap>
          {orderPaymentsData.map((order) => {
            return (
              <S.Card key={`card-id-${order?.id}`}>
                <AccordionStyle>
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <S.CardHeaderContainer>
                        <S.CardHeader>
                          <S.TitleContainer onClick={preventCollapse}>
                            {showCheckboxes ? (
                              <SelectAllCheckbox
                                id={order?.id}
                                title={<S.CardTitle>{`Ordem #${order?.id}`}</S.CardTitle>}
                                selectedItems={selectedIds}
                                setSelectedItems={setSelectedIds}
                                activities={order?.tasks}
                                checked={selectedIds[order?.id]?.length === order?.tasks?.length}
                              />
                            ) : (
                              <S.CardTitle>{`Ordem #${order?.id}`}</S.CardTitle>
                            )}

                            <S.OrderDescription>
                              {format(new Date(order?.created_at), 'dd/MM/yyyy')}
                            </S.OrderDescription>
                          </S.TitleContainer>
                          <S.OrderDescription>
                            {`Valor da Nota - `}
                            {returnOrderValue(order?.value)}
                          </S.OrderDescription>
                          <S.OrderDescription>{`${order?.responsible_info?.name} - ${order?.responsible_info?.email}`}</S.OrderDescription>
                          {order?.status === 'd' && order?.payment_date && (
                            <S.OrderDescription>
                              Pago em
                              <b>{formatDate(order?.payment_date)}</b>
                            </S.OrderDescription>
                          )}
                        </S.CardHeader>
                        {(showOrderButtons ||
                          showResponsibleButtons(order?.responsible_info?.email)) && (
                          <S.OrderButtonsContainer onClick={preventCollapse}>
                            <S.SecondaryButton
                              onClick={() =>
                                cancelOrder(
                                  order?.id,
                                  order?.value,
                                  format(new Date(order?.created_at), 'dd/MM/yyyy'),
                                )
                              }
                            >
                              <S.StyledThrashIcon />
                              Cancelar ordem
                            </S.SecondaryButton>
                            <S.PrimaryButton
                              onClick={() =>
                                fillForms(
                                  order?.id,
                                  order?.value,
                                  format(new Date(order?.created_at), 'dd/MM/yyyy'),
                                  'sendForms',
                                )
                              }
                            >
                              <S.StyledExternalLinkIcon />
                              Preencher Forms - NF
                            </S.PrimaryButton>
                          </S.OrderButtonsContainer>
                        )}
                        {showErrorButton && (
                          <S.OrderButtonsContainer onClick={preventCollapse}>
                            <S.PrimaryButton
                              onClick={() =>
                                fillForms(
                                  order?.id,
                                  order?.value,
                                  format(new Date(order?.created_at), 'dd/MM/yyyy'),
                                  'resendForms',
                                )
                              }
                            >
                              <S.StyledExternalLinkIcon />
                              Reenviar Forms NF
                            </S.PrimaryButton>
                          </S.OrderButtonsContainer>
                        )}
                        {showManageErrorButton && (
                          <S.OrderButtonsContainer onClick={preventCollapse}>
                            <S.PrimaryButton
                              onClick={() =>
                                orderError(
                                  order?.id,
                                  order?.value,
                                  format(new Date(order?.created_at), 'dd/MM/yyyy'),
                                )
                              }
                            >
                              <RiErrorWarningLine size={16} color="#fff" />
                              Erro em ordem
                            </S.PrimaryButton>
                          </S.OrderButtonsContainer>
                        )}
                        {showCopyFormsLinkButton && (
                          <S.OrderButtonsContainer onClick={preventCollapse}>
                            <S.PrimaryButton
                              onClick={() => {
                                navigator.clipboard.writeText(PARTNER_FORMS_LINK);
                                toast.success('Link do forms copiado com sucesso!');
                              }}
                            >
                              <S.StyledCopyIcon />
                              Copiar link do forms
                            </S.PrimaryButton>
                          </S.OrderButtonsContainer>
                        )}
                      </S.CardHeaderContainer>
                    </AccordionSummary>
                    <AccordionDetails>
                      <S.CardContent>
                        <S.TableHeader>
                          <S.TableHeaderTitle width="120px">Multiplicador</S.TableHeaderTitle>
                          <S.TableHeaderTitle>Valor</S.TableHeaderTitle>
                          <S.TableHeaderTitle>Quantidade</S.TableHeaderTitle>
                          <S.TableHeaderTitle>Multa</S.TableHeaderTitle>
                          <S.TableHeaderTitle>Total</S.TableHeaderTitle>
                          <S.TableHeaderTitle width="160px">Pagamento</S.TableHeaderTitle>
                          <S.EmptySpace />
                        </S.TableHeader>

                        {order?.tasks.length > 0 && (
                          <>
                            {order?.tasks.map((task) => {
                              return (
                                <S.TableItem key={`table-item-${task.id}`}>
                                  <S.TableTitle>{task.name}</S.TableTitle>
                                  <S.TableRightInformations>
                                    <S.TableText width="120px">
                                      {returnMultiplier(task.multiplier)}
                                    </S.TableText>
                                    <S.TableText>
                                      {returnFormattedPrice(task?.task_price)}
                                    </S.TableText>
                                    <S.TableText>{task.amount}</S.TableText>
                                    <S.TableText errorColor={!!task.values_to_pay?.penalty}>
                                      {returnFormattedPrice(task.values_to_pay?.penalty)}
                                    </S.TableText>
                                    <S.TableText>
                                      {returnFormattedPrice(task.values_to_pay?.total_calculated)}
                                    </S.TableText>
                                    <S.TableText width="160px">
                                      {ReturnStatusTag(task.status)}
                                    </S.TableText>
                                    <S.IconContainer
                                      onClick={() => handleOrderChat(task?.id, task?.name)}
                                    >
                                      <ChatIcon />
                                    </S.IconContainer>
                                    <S.IconContainer
                                      onClick={() => handleOrderOffcanvas(task.id, task.name)}
                                    >
                                      <ExternalLinkComponent />
                                    </S.IconContainer>
                                  </S.TableRightInformations>
                                </S.TableItem>
                              );
                            })}
                          </>
                        )}
                      </S.CardContent>
                    </AccordionDetails>
                  </Accordion>
                </AccordionStyle>
              </S.Card>
            );
          })}
        </S.Wrap>
      )}
    </>
  );
}

export default OrderPayments;
