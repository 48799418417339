import React from 'react';
import PropTypes from 'prop-types';

import { FaCheck } from 'react-icons/fa';
import { CheckboxContainer, HiddenCheckbox, Checkbox, Text } from './styles';

function CheckboxDS({ checked, onChange, label, styleContainer, disabled, type, children }) {
  function handleCheckboxChange(event) {
    if (disabled) {
      return;
    }
    if (onChange) {
      event.stopPropagation();
      onChange(!checked);
    }
  }

  return (
    <CheckboxContainer
      checked={checked}
      onClick={handleCheckboxChange}
      style={styleContainer}
      disabled={disabled}
    >
      <HiddenCheckbox onChange={(event) => handleCheckboxChange(event)} checked={checked} />
      <Checkbox checked={checked}>
        {checked && (
          <FaCheck color={disabled ? '#CCD2DE' : '#FFF'} size={type === 'default' ? 16 : 12} />
        )}
      </Checkbox>
      <Text type={type} addMarginLeft={!!label}>
        {label}
        {children}
      </Text>
    </CheckboxContainer>
  );
}
CheckboxDS.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  styleContainer: PropTypes.shape(),
  disabled: PropTypes.bool,
};

CheckboxDS.defaultProps = {
  type: 'default',
  label: null,
  checked: false,
  styleContainer: {},
  disabled: false,
};
export default CheckboxDS;
