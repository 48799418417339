import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimePickerComponent({
  label,
  value,
  defaultValue,
  handleChange,
  small,
  fullWidth,
  timeSteps,
}) {
  const props = {
    ...(small && {
      '& .MuiInputBase-root': {
        height: '40px',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 11px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    }),
    ...(fullWidth && { width: '100%' }),
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <TimePicker
        defaultValue={defaultValue && dayjs(defaultValue)}
        views={['hours', 'minutes', 'seconds']}
        label={label}
        onChange={(newValue) => handleChange(newValue)}
        value={value && dayjs(value)}
        sx={props}
        timeSteps={timeSteps}
      />
    </LocalizationProvider>
  );
}
