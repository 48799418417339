/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import Axios from 'axios';

import { useLessonSubject } from '~/hooks-querys/lessonSubject';
import { useLessonModuleBySubject } from '~/hooks-querys/lessonModule';

import ModalConfirmation from '~/components/ModalConfirmation';

import Loader from '~/components/Loader';
import HeaderComponent from '~/components/HeaderComponent';
import ListAndOrder from '~/components/ListAndOrder';
import GreenBorder from '~/components/GreenBorder';

import { LessonSubjectAPICMS, LessonModuleAPICMS } from '~/services/apiCallsCMS';
import history from '~/services/history';

import { BiPlusCircle } from 'react-icons/bi';
import CreateLesson from './CreateLesson';
import * as S from './styles';

function Modules() {
  const location = useLocation();
  const { id } = useParams();
  const [formatLessonSubjects, setFormatLessonSubjects] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [messageConfirmation, setMessageConfirmation] = useState('');
  const [idDelete, setIdDelete] = useState(null);
  const [isLessonSubjectDelete, setIsLessonSubjectDelete] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [loadingModules, setLoadingModules] = useState(false);
  const [openCreateLesson, setOpenCreateLesson] = useState(false);
  const [selectedLessonSubject, setSelectedLessonSubject] = useState(null);
  const [selectedLessonModule, setSelectedLessonModule] = useState(null);
  const [courseName] = useState(location.state?.courseName);

  const {
    data: lessonSubjects,
    isFetching: fetchingLessonSubject,
    refetch: refetchList,
  } = useLessonSubject(
    {
      ordering: 'order',
      course: id,
      no_page: true,
    },
    {
      config: {
        enabled: !!id,
      },
    },
  );

  const { data: lessonModules, isFetching: lessonModuleFetching } = useLessonModuleBySubject(
    { lesson_subject: selectedSubject, no_page: true },
    {
      config: {
        enabled: !!selectedSubject,
      },
    },
  );

  function handleDeleteLessonSubject(id) {
    setShowModalConfirmation(true);
    setIdDelete(id);
    setTitleConfirmation('Excluir módulo de aula');
    setIsLessonSubjectDelete(true);
    setMessageConfirmation('Você deseja excluir esse módulo de aula?');
  }

  function handleDeleteLessonModule(id) {
    setShowModalConfirmation(true);
    setIdDelete(id);
    setTitleConfirmation('Excluir aula');
    setIsLessonSubjectDelete(false);
    setMessageConfirmation('Você deseja excluir essa aula?');
  }

  function handleNewLesson(lessonSubject) {
    setSelectedLessonSubject(lessonSubject);
    setOpenCreateLesson(true);
  }

  function handleUpdateLesson(lessonModule) {
    setSelectedLessonModule(lessonModule);
    setOpenCreateLesson(true);
  }

  function handleCloseCreateLesson() {
    setOpenCreateLesson(false);
    setIsLoadingAll(true);
    setSelectedLessonModule(null);
    setSelectedSubject(null);
    refetchList();
  }

  function handleEditLessonModule(lessonModuleId) {
    history.push({
      pathname: `/courses/${id}/lesson-module/${lessonModuleId}`,
    });
  }

  const buttonsActionsLessonSubject = [
    {
      hasBorder: true,
      text: 'Adicionar aula',
      icon: 'add',
      action: (id) => () => handleNewLesson(id),
    },
    {
      hasBorder: false,
      text: 'Excluir',
      icon: 'delete',
      action: (id) => () => handleDeleteLessonSubject(id),
      permission: 'delete_course',
    },
  ];

  const buttonsActionsLessonModules = [
    {
      hasBorder: false,
      text: 'Configuração',
      icon: 'configuration',
      action: (id) => () => handleUpdateLesson(id),
    },
    {
      hasBorder: false,
      text: 'Editar',
      icon: 'edit',
      action: (id) => () => handleEditLessonModule(id),
    },
    {
      hasBorder: false,
      text: 'Excluir',
      icon: 'delete',
      action: (id) => () => handleDeleteLessonModule(id),
      permission: 'delete_course',
    },
  ];

  const transformLessonSubjects = (lessonSubject) => {
    if (!lessonSubject.id || !lessonSubject.name) return;
    return {
      name: lessonSubject.name,
      id: lessonSubject.id,
      order_number: lessonSubject.order,
      buttons: buttonsActionsLessonSubject,
      content: [],
    };
  };

  const transformLessonModules = (lessonModule) => {
    if (!lessonModule.id || !lessonModule.name) return;
    return {
      name: lessonModule.name,
      id: lessonModule.id,
      order_number: lessonModule.order,
      buttons: buttonsActionsLessonModules,
    };
  };

  useEffect(() => {
    if (!lessonSubjects || fetchingLessonSubject) return;
    setFormatLessonSubjects(lessonSubjects.map(transformLessonSubjects));
    setIsLoadingAll(false);
  }, [lessonSubjects, fetchingLessonSubject]);

  useEffect(() => {
    setLoadingModules(true);
  }, [selectedSubject]);

  useEffect(() => {
    if (
      formatLessonSubjects.length === 0 ||
      !lessonModules ||
      lessonModuleFetching ||
      fetchingLessonSubject
    )
      return;

    const lessonSubject = formatLessonSubjects.find(
      (item) => item.id === lessonModules.lessonSubject,
    );
    lessonSubject.content = lessonModules.lessonModules.map(transformLessonModules);

    setLoadingModules(false);
  }, [lessonModules, lessonModuleFetching]);

  function createLessonSubject() {
    if (disableButton) return;
    setDisableButton(true);
    const data = { name: '**Novo Módulo**', order: lessonSubjects.length + 1 };
    const source = Axios.CancelToken.source();
    LessonSubjectAPICMS.create(data, source.token)
      .then(async (result) => {
        const lessonSubjectId = result.data.id;
        LessonSubjectAPICMS.addToCourse(lessonSubjectId, { course: id }, source.token)
          .then(async (_) => {
            setDisableButton(false);
            toast.success(`Módulo criado com sucesso!`);
            setSelectedSubject(null);
            refetchList();
          })
          .catch(() => {
            setDisableButton(false);
            toast.error(`Não foi possível associar o módulo ao curso`);
          });
      })
      .catch(() => {
        setDisableButton(false);
        toast.error(`Não foi possível criar o módulo`);
      });
  }

  function deleteLessonSubject(id) {
    const source = Axios.CancelToken.source();
    LessonSubjectAPICMS.delete(id, source.token).then(async (_result) => {
      setShowModalConfirmation(false);
      toast.success(`Módulo excluído com sucesso!`);
      setSelectedSubject(null);
      refetchList();
    });
  }

  function deleteLessonModule(id) {
    const source = Axios.CancelToken.source();
    LessonModuleAPICMS.delete(id, source.token).then(async (_result) => {
      setShowModalConfirmation(false);
      toast.success(`Aula excluída com sucesso!`);
      setIsLoadingAll(true);
      setSelectedSubject(null);
      refetchList();
    });
  }

  function updateLessonSubject(id, name) {
    const source = Axios.CancelToken.source();
    LessonSubjectAPICMS.update(id, { name }, source.token).then(async (_result) => {
      setSelectedSubject(null);
      refetchList();
    });
  }

  function updateOrderListSubject(ids) {
    const source = Axios.CancelToken.source();
    LessonSubjectAPICMS.updateOrder({ ids }, source.token).then(async (_result) => {
      setSelectedSubject(null);
      refetchList();
    });
  }

  function updateOrderListModules(ids) {
    const source = Axios.CancelToken.source();
    LessonModuleAPICMS.updateOrder({ ids }, source.token).then(async (_result) => {
      setIsLoadingAll(true);
      setSelectedSubject(null);
      refetchList();
    });
  }

  function deleteAction() {
    if (isLessonSubjectDelete) {
      deleteLessonSubject(idDelete);
    } else {
      deleteLessonModule(idDelete);
    }
  }

  return (
    <>
      {isLoadingAll ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent title={courseName} permissions={['view_course']} />
          <S.Wrap>
            <S.Container>
              <S.ContainerConfig>
                <S.FormContainer>
                  <S.StepContainer>
                    <GreenBorder />
                    <S.Step>
                      <S.StepOder>1</S.StepOder>
                      <S.StepText>Configuração de módulos</S.StepText>
                    </S.Step>
                    <br />
                    <S.RowContainer>
                      <ListAndOrder
                        parentData={formatLessonSubjects}
                        setParentData={setFormatLessonSubjects}
                        setSelectedParent={setSelectedSubject}
                        parentAllowDrag
                        allowEditLabel
                        childAllowDrag
                        hasChild
                        childLoading={loadingModules}
                        updateDataName={updateLessonSubject}
                        updateOrderListParent={updateOrderListSubject}
                        updateOrderListChild={updateOrderListModules}
                      />
                      <S.FooterButton>
                        <S.Label>Adicionar</S.Label>
                        <S.Button disabled={disableButton} onClick={() => createLessonSubject()}>
                          <BiPlusCircle size={20} color="#077859" />
                          <S.ButtonLabel>Módulo</S.ButtonLabel>
                        </S.Button>
                      </S.FooterButton>
                    </S.RowContainer>
                  </S.StepContainer>
                </S.FormContainer>
              </S.ContainerConfig>
              {showModalConfirmation && (
                <ModalConfirmation
                  title={titleConfirmation}
                  message={messageConfirmation}
                  actionText="Excluir"
                  showModal={showModalConfirmation}
                  handleClose={() => setShowModalConfirmation(false)}
                  handleAction={() => deleteAction()}
                />
              )}
              {openCreateLesson && (
                <CreateLesson
                  showModal={openCreateLesson}
                  handleClose={handleCloseCreateLesson}
                  course={id}
                  courseName={courseName}
                  lessonModule={selectedLessonModule}
                  lessonSubject={selectedLessonSubject}
                />
              )}
            </S.Container>
          </S.Wrap>
        </>
      )}
    </>
  );
}

export default Modules;
