import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@medway-npm/ds-medstar';
import Modal from '@material-ui/core/Modal';
import { BiPlusCircle } from 'react-icons/bi';
import { ReactComponent as SaveIcon } from '~/assets/drops-save.svg';
import Inputs from '~/components/Inputs';
import TimePickerComponent from '~/components/TimePicker';
import SelectWithPagination from '~/components/SelectWithPagination';
import CheckboxDS from '~/components/CheckboxDS';
import { loadCfas } from './utils';
import { secondsToHms, timeToSeconds } from '~/utils/util';
import { ModalContent, ModalTitle, ButtonContainer } from './styles';

export default function HighlightModal({
  show,
  close,
  seconds,
  information,
  setInformations,
  fullDuration,
  handleSaveHighlight,
  loading,
  isEdit,
  loadingTag,
  parentTag,
}) {
  const handleChangeHighlight = (event) => {
    setInformations({ ...information, name: event.target.value });
  };

  const handleChangeTime = (event) => {
    const time = `${event.$d.getHours()}:${event.$d.getMinutes()}:${event.$d.getSeconds()}`;
    setInformations({ ...information, seconds: time });
  };

  const handleTag = (event) => {
    setInformations({ ...information, tag: event });
  };

  const handleCheck = (event) => {
    let dataToUpdate = {
      hasNoTag: event,
    };
    if (event) {
      dataToUpdate = { ...dataToUpdate, tag: null };
    }
    setInformations({ ...information, ...dataToUpdate });
  };

  const handleSave = () => {
    if (!information.name) {
      return toast.error('O nome do destaque é obrigatório');
    }

    const actualSeconds = timeToSeconds(information.seconds);
    if (actualSeconds > fullDuration) {
      return toast.error('O início do destaque não pode ser maior do que a duração do vídeo');
    }

    handleSaveHighlight();
  };

  useEffect(() => {
    if (isEdit && information?.seconds) return;
    setInformations({ ...information, seconds: secondsToHms(seconds, true) });
  }, [seconds]);

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Modal open={show} onClose={close}>
      <ModalContent>
        <ModalTitle>{isEdit ? 'Editar destaque' : 'Adicionar destaque'}</ModalTitle>
        <Inputs
          type="text"
          placeholder="Nome do destaque"
          onChange={(event) => handleChangeHighlight(event)}
          value={information.name}
        />
        <TimePickerComponent
          label="Início do destaque"
          value={`2024-01-01T${information.seconds}`}
          handleChange={handleChangeTime}
          timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
        />
        <SelectWithPagination
          name="tag"
          height={48}
          value={information.tag}
          onChange={handleTag}
          loadOptions={(search, loadedOptions, page) =>
            loadCfas(search, loadedOptions, page, { parent_tag: parentTag?.[0] || '' })
          }
          placeholder={loadingTag ? 'Carregando...' : 'Tags'}
          placeholderStyle={{
            display: 'flex',
            fontSize: '14px',
          }}
          valueContainerStyle={{
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap',
          }}
          containerWidth={'100%'}
          singleValueStyle={selectValueStyle}
          disabled={information.hasNoTag || loadingTag}
        />
        <CheckboxDS checked={information.hasNoTag} onChange={handleCheck} label="Tag não existe" />
        <ButtonContainer>
          {isEdit ? (
            <Button size="medium" startIcon={<SaveIcon />} onClick={handleSave} loading={loading}>
              Salvar
            </Button>
          ) : (
            <Button
              size="medium"
              startIcon={<BiPlusCircle />}
              onClick={handleSave}
              loading={loading}
            >
              Adicionar
            </Button>
          )}
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
}
