import React from 'react';

import { RiEdit2Line } from 'react-icons/ri';

import {
  EditEnunciated,
  OriginalInstitution,
  QuestionNumber,
  QuestionTopContainer,
  QuestionTopLeft,
  QuestionTopRight,
  Tag,
  TagName,
} from './styles';

export default function QuestionHeader({
  question,
  question_number,
  track_name,
  action,
  handleEdit,
  editContent,
}) {
  return (
    <QuestionTopContainer>
      <QuestionTopLeft>
        <QuestionNumber id="question-component-title">
          {`Questão ${question_number} - ${question.year} - ID ${question.id}`}
          {`  `}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_BASE_URL}/admin/question/question/${question.id}`}
          >
            (ver no cms)
          </a>
        </QuestionNumber>
        <OriginalInstitution id="question-component-institution">
          {question.institution_name || question.residency_degree}
          {question.question_exam_category ? ` | ${question.question_exam_category}` : ''}
        </OriginalInstitution>
        {question.canceled && (
          <Tag>
            <TagName>Anulada</TagName>
          </Tag>
        )}
      </QuestionTopLeft>
      {action === 'text' && (
        <QuestionTopRight>
          <EditEnunciated onClick={() => handleEdit(!editContent)}>
            <RiEdit2Line color="#3B3FB6" size={18} />
          </EditEnunciated>
        </QuestionTopRight>
      )}
    </QuestionTopContainer>
  );
}
