import styled from 'styled-components';
import { ReactComponent as HighlightIcon } from '~/assets/highlight-icon.svg';
import { ReactComponent as TrashIcon } from '~/assets/trash-icon.svg';

export const Wrap = styled.div`
  display: flex;
  padding: 24px 32px;
  gap: 32px;
  margin-top: 72px;
`;

export const ContentArea = styled.div`
  display: flex;
  width: calc(50vw - 48px);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;
  width: 100%;
  position: absolute;
  left: 80px;
  top: 0px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 0px;
`;

export const VideoContainer = styled.div`
  border: none;
  width: 100%;
  height: 52vh;

  iframe {
    border: none;
    border-radius: 10px;
  }
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
  height: 916px;
  background: #ffffff;
  border-radius: 4px;
  justify-content: ${(props) => (props.isEmpty ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.isEmpty ? 'center' : 'flex-start')};
`;

export const EmptyIconAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const EmptyTextAndButton = styled(EmptyIconAndText)`
  gap: 24px;
`;

export const StyledHighlightIcon = styled(HighlightIcon)`
  width: 64px;
  height: 64px;

  path {
    fill: #00205b;
  }
`;

export const EmptyText = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #3d5582;
`;

export const ListTitle = styled.p`
  margin: 0;
  height: 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #00205b;
`;

export const HighlightItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e5e8ee;
  opacity: ${(props) => (props.toDelete ? '0.5' : '1')};
  pointer-events: ${(props) => (props.toDelete ? 'none' : 'unset')};
`;

export const HighlightCfa = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const CfaTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  background: #00205b;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

export const CfaText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: #3d5582;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HighlightInformations = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HighlightDetails = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const MockThumbnail = styled.div`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  width: 100px;
  height: 67px;
  border-radius: 4px;
`;

export const HighlightTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3d5582;
`;

export const ControlHighlightItems = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const TimestampHighlight = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #3d5582;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

export const StyledTrashIcon = styled(TrashIcon)`
  width: 15px;
  height: 17px;
  path {
    fill: #fff;
  }
`;
