import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import clsx from 'clsx';

import ProfileComponent from '~/components/ProfileComponent';

import Sidebar from '~/components/Sidebar';
import { useHistory } from 'react-router-dom';
import { useStyles, Container, Footer, MainContainer } from './styles';

export default function DefaultLayout({ children }) {
  const { location } = useHistory();
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);

  const [showProfile, setShowProfile] = useState(false);

  const listOfPathsToRemoveFooter = ['/new', 'preview', 'create-activity'];
  const removeFooter = listOfPathsToRemoveFooter.some((item) => location?.pathname.includes(item));

  return (
    <>
      <main className={clsx(classes.content)}>
        <MainContainer>
          <Sidebar />
          <Container>{children}</Container>
        </MainContainer>
        {!removeFooter && (
          <Footer id="platform-general-footer">Plataforma Medway {new Date().getFullYear()}</Footer>
        )}
      </main>

      <ProfileComponent
        show={showProfile}
        close={() => {
          setShowProfile(false);
        }}
      />
    </>
  );
}
