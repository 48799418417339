import styled from 'styled-components';
import { ReactComponent as ArrowLeft } from '~/assets/drops-arrow-left.svg';
import { ReactComponent as InformationIcon } from '~/assets/information_logo.svg';

export const ModalContent = styled.div`
  padding: 32px 56px;
  border-bottom: 1px solid #e5e8ee;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`;

export const ModalFooter = styled.div`
  display: flex;
  gap: 16px;
  padding: 16px 24px;
  width: 100%;
  justify-content: space-between;
`;

export const StyledArrowLeft = styled(ArrowLeft)`
  path {
    fill: #019d8a;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: 544px;

  > div {
    width: 100%;
  }
`;

export const Alert = styled.div`
  display: flex;
  padding: 16px 20px;
  gap: 20px;
  width: 100%;
  height: 80px;
  background: #dae6f4;
  border-radius: 8px;
`;

export const AlertText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #124a8f;
`;

export const StyledInformationIcon = styled(InformationIcon)`
  flex-shrink: 0;
  path {
    fill: #1862bc;
  }
`;
