import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ProtectedContent from '~/components/ProtectedContent';
import HeaderComponent from '~/components/HeaderComponent';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';

import SearchIcon from '~/assets/drops-search.svg';
import EyeIcon from '~/assets/drops-eye.svg';
import ArrowRightIcon from '~/assets/drops-arrow-right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { requestChapter } from '~/store/modules/chapter/actions';
import Loader from '~/components/Loader';
import DropsPrevious from '~/assets/dropsPrevious';
import DropsNext from '~/assets/dropsNext';
import SelectWithPagination from '~/components/SelectWithPagination';
import { CourseAPICMS } from '~/services/apiCallsCMS';
import { HiUser } from 'react-icons/hi';
import * as S from './styles';

function ListChapter() {
  const { location } = useHistory();
  const dispatch = useDispatch();

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pressEnter, setPressEnter] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const transformOptions = (course) => {
    if (!course.id || !course.name) return;

    return {
      label: course.name,
      key: course.id,
      value: course.id,
      course,
    };
  };

  const loadCourses = useCallback(async (search, loadedOptions, { page }) => {
    setLoading(true);
    const { data } = await CourseAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });
    setLoading(false);
    return {
      options: data.results.map(transformOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const {
    chapters: { chapters, requesting },
  } = useSelector((state) => state.chapter);

  const getListChapters = useCallback(() => {
    dispatch(requestChapter(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    getListChapters();
  }, [filter.page]);

  const handleAddNew = () => {
    Mixpanel.track("Clicou 'Lista de capítulos - Novo capítulo'", {});
    history.push({ pathname: `${location.pathname}/new`, state: { removeFooter: true } });
  };

  const handleEdit = (chapter) => {
    Mixpanel.track("Clicou 'Lista de capítulos - Botão de editar um capítulo'", {
      'Nome do capítulo': chapter?.name,
      'Nome da apostila': chapter?.subject_name,
      'Nome do curso':
        chapter?.courses_names?.length > 0
          ? chapter?.courses_names?.join('; ')
          : 'Não especificado',
    });
    window.open(`${location.pathname}/edit/${chapter?.id}`, '_blank');
  };

  const handlePreview = (chapter) => {
    Mixpanel.track("Clicou 'Lista de capítulos - Botão de visualizar um capítulo'", {
      'Nome do capítulo': chapter?.name,
      'Nome da apostila': chapter?.subject_name,
      'Nome do curso':
        chapter?.courses_names?.length > 0
          ? chapter?.courses_names?.join('; ')
          : 'Não especificado',
    });
    window.open(`${location.pathname}/preview/${chapter?.id}`, '_blank');
  };

  const convertDiffTime = (updated_at) => {
    const today = new Date().toISOString().split('T')[0];
    const last_update = updated_at.split('T')[0];
    const diffInMs = new Date(today) - new Date(last_update);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays;
  };

  const handleNext = () => {
    setFilter({ ...filter, page: filter.page + 1 });
  };
  const handlePrevious = () => {
    setFilter({ ...filter, page: filter.page - 1 });
  };

  const changeFilter = (value) => {
    setFilter({ ...filter, search: value, page: 1 });

    document.addEventListener(
      'keypress',
      (e) => {
        if (e.key === 'Enter') {
          setPressEnter(true);
        } else {
          setPressEnter(false);
        }
      },
      false,
    );
  };

  const handleFilter = () => {
    getListChapters();
    Mixpanel.track("Clicou 'Lista de capítulos - Botão de filtrar'", {
      Pesquisar: !filter.search ? 'Não especificado' : filter.search,
      Curso: !filter.course ? 'Não especificado' : selectedCourse.label,
    });
  };

  useEffect(() => {
    if (pressEnter) handleFilter();
  }, [pressEnter]);

  return (
    <>
      <HeaderComponent
        title="Capítulos"
        action={() => handleAddNew()}
        actionText="Adicionar Novo"
        permissions={['add_chapter']}
      />
      <S.Wrap>
        <S.Container>
          <S.FilterContainer>
            <S.Header>Filtrar dados</S.Header>
            <S.Content>
              <S.Filter>
                <img src={SearchIcon} alt="Search icon" />
                <input
                  type="text"
                  value={filter.search}
                  onChange={(e) => changeFilter(e.target.value)}
                  placeholder="Pesquisar capítulo"
                />
              </S.Filter>
              <SelectWithPagination
                name="course"
                height={40}
                width="100%"
                value={selectedCourse}
                onChange={(e) => {
                  setSelectedCourse(e);
                  setFilter({ ...filter, course: e.value, page: 1 });
                }}
                loadOptions={loadCourses}
                placeholder={loading ? 'Carregando...' : 'Curso'}
              />

              <S.ButtonFilter onClick={() => handleFilter()}>Filtrar</S.ButtonFilter>
            </S.Content>
          </S.FilterContainer>
          {requesting ? (
            <Loader />
          ) : (
            <>
              <S.ListChapterContainer>
                {chapters?.results?.map((chapter) => {
                  return (
                    <S.CardBook key={chapter?.id}>
                      <S.HeaderCardBook>
                        <S.InfoBook>
                          <S.TitleChapter>{chapter.name}</S.TitleChapter>
                          <S.SubjectName>{chapter.subject_name}</S.SubjectName>
                          {chapter.courses_names?.map((course, index) => (
                            <S.CouserName key={index}>{course}</S.CouserName>
                          ))}
                        </S.InfoBook>
                        <S.Controls>
                          {chapter.published && (
                            <S.Publish published={chapter.published}>
                              <S.SwitchButton>
                                <input type="checkbox" defaultChecked={chapter.published} />
                                <span />
                              </S.SwitchButton>
                              <span>Publicado</span>
                            </S.Publish>
                          )}
                          <ProtectedContent permissions={['view_chapter']}>
                            <S.ButtonView onClick={() => handlePreview(chapter)}>
                              Visualizar
                              <img src={EyeIcon} alt="Search icon" />
                            </S.ButtonView>
                          </ProtectedContent>
                          <ProtectedContent permissions={['change_chapter']}>
                            <S.ButtonEdit onClick={() => handleEdit(chapter)}>
                              Editar
                              <img src={ArrowRightIcon} alt="Arrow right icon" />
                            </S.ButtonEdit>
                          </ProtectedContent>
                        </S.Controls>
                      </S.HeaderCardBook>
                      <S.FooterCardBook>
                        <S.Avatar>
                          <S.AvatarImage url={chapter.physicians[0]?.avatar}>
                            {!chapter.physicians[0]?.avatar && <HiUser color="#00205B" size={24} />}
                          </S.AvatarImage>
                          <S.UserName>{chapter.physicians[0]?.name}</S.UserName>
                        </S.Avatar>
                        <S.LastModified>
                          Última modificação{' '}
                          <strong>há {convertDiffTime(chapter.updated_at)} dias</strong>
                        </S.LastModified>
                      </S.FooterCardBook>
                    </S.CardBook>
                  );
                })}
              </S.ListChapterContainer>
              <S.ContainerPagination>
                <span>
                  Mostrando <strong>{chapters?.results?.length}</strong> itens
                </span>
                <S.Pagination>
                  <S.ButtonPagination
                    onClick={() => handlePrevious()}
                    disabled={!chapters?.previous}
                  >
                    <DropsPrevious /> Anterior
                  </S.ButtonPagination>
                  <S.ButtonPagination onClick={() => handleNext()} disabled={!chapters?.next}>
                    Próximo <DropsNext />
                  </S.ButtonPagination>
                </S.Pagination>
                <span>
                  <strong>{chapters?.count}</strong> capítulos
                </span>
              </S.ContainerPagination>
            </>
          )}
        </S.Container>
      </S.Wrap>
    </>
  );
}

export default ListChapter;
