import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SelectComponent from '~/components/SelectComponent';
import ModalWithHeader from '~/components/ModalWithHeader';
import InputMuiLabel from '~/components/InputMuiLabel';
import { DefaultUsersAPICMS } from '~/services/apiCallsCMS';

import {
  ColumnContainer,
  ModalContent,
  ModalFooter,
  SelectContainer,
  StyledArrowLeft,
  Alert,
  AlertText,
  StyledInformationIcon,
} from './styles';

import { useFilterSpecialitys } from '~/hooks-querys/filters';
import { Button } from '@medway-npm/ds-medstar';
import { ReactComponent as RedoIcon } from '~assets/redo-icon.svg';

export default function ModalDataPartner({
  showModal,
  handleClose,
  responsible,
  multiplierOptions,
  setResponsible,
}) {
  const [responsibleMultiplier, setResponsibleMultiplier] = useState(
    multiplierOptions.find((option) => parseFloat(option.value) === responsible?.multiplier),
  );
  const [specialities, setSpecialities] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);

  const { data: specialitiesData } = useFilterSpecialitys({
    main_speciality: true,
    no_page: true,
  });

  const transformOptions = (item) => {
    if (!item.id || !item.name) return;

    return {
      label: item.name,
      key: item.id,
      value: item.id,
      item,
    };
  };

  const handleChangeMultiplier = (multiplier) => {
    setResponsibleMultiplier(multiplier);
  };

  const handleSpeciality = (speciality) => {
    setSelectedSpeciality(speciality);
  };

  const handleUpdate = () => {
    DefaultUsersAPICMS.update(responsible.value, {
      ...(responsibleMultiplier && { multiplier: responsibleMultiplier.value }),
      ...(selectedSpeciality && { speciality_id: selectedSpeciality.value }),
    })
      .then((response) => {
        setResponsible({
          ...responsible,
          ...{
            multiplier: response.data.multiplier,
            speciality: response.data.speciality_id,
          },
        });
        toast.success('Dados do parceiro atualizados com sucesso!');
      })
      .catch(() => toast.error('Erro ao atualizar dados do parceiro. Tente novamente mais tarde'))
      .finally(() => {
        handleClose();
      });
  };

  useEffect(() => {
    if (!specialitiesData) return;
    setSpecialities((specialities) => [...specialities, ...specialitiesData.map(transformOptions)]);
  }, [specialitiesData]);

  useEffect(() => {
    if (!specialities) return;
    setSelectedSpeciality(
      specialities.find((speciality) => speciality.value === responsible?.speciality),
    );
  }, [specialities]);

  return (
    <ModalWithHeader show={showModal} close={handleClose} title="Atualizar dados do parceiro">
      <ModalContent>
        <ColumnContainer>
          <InputMuiLabel
            label="Nome do parceiro"
            disabled
            id="nameResponsible"
            type="text"
            value={responsible?.name}
            handleChange={() => {
              return;
            }}
          />
          <InputMuiLabel
            label="E-mail do parceiro"
            disabled
            id="emailResponsible"
            type="text"
            value={responsible?.email}
            handleChange={() => {
              return;
            }}
          />
          <SelectContainer>
            <SelectComponent
              name="modal-multiplier"
              placeholder="Multiplicador"
              text={responsibleMultiplier && 'Multiplicador'}
              onChange={handleChangeMultiplier}
              options={multiplierOptions}
              value={responsibleMultiplier}
              width="100%"
              height={48}
              controlStyle={{ marginLeft: '0' }}
            />
          </SelectContainer>
          <SelectContainer>
            <SelectComponent
              name="modal-speciality"
              placeholder="Especialidade"
              text={selectedSpeciality && 'Especialidade'}
              onChange={handleSpeciality}
              options={specialities}
              value={selectedSpeciality}
              width="100%"
              height={48}
              controlStyle={{ marginLeft: '0' }}
            />
          </SelectContainer>
          <Alert>
            <StyledInformationIcon />
            <AlertText>Dados atualizados refletirão em atividades atuais e futuras</AlertText>
          </Alert>
        </ColumnContainer>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="tertiary"
          size="medium"
          startIcon={<StyledArrowLeft />}
          onClick={handleClose}
        >
          Voltar
        </Button>
        <Button size="medium" endIcon={<RedoIcon />} onClick={handleUpdate}>
          Atualizar
        </Button>
      </ModalFooter>
    </ModalWithHeader>
  );
}
