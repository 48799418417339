import React, { memo } from 'react';
import { TextField } from '@material-ui/core';

import { InputStyle } from './styles';

function InputMuiLabel({
  label,
  value,
  placeholder,
  textfieldStyle,
  size = 'large', //large = 48px, medium = 40px
  type,
  id,
  inputCustomStyles,
  disabled,
  handleChange,
  height,
}) {
  return (
    <InputStyle inputCustomStyles={inputCustomStyles} size={size}>
      <TextField
        type={type}
        id={`input-component-${id}`}
        label={label}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        onChange={handleChange}
        style={textfieldStyle}
        disabled={disabled}
      />
    </InputStyle>
  );
}

export default memo(InputMuiLabel);
