import React from 'react';
import { Switch } from 'react-router-dom';
import ListChapter from '~/pages/Chapters/ListChapter';
import CreateChapter from '~/pages/Chapters/CreateChapter';
import EditChapter from '~/pages/Chapters/EditChapter';
import PreviewChapter from '~/pages/Chapters/PreviewChapter';

import SignIn from '~/pages/SignIn';
import ResetPassword from '~/pages/ResetPassword';
import Dashboard from '~/pages/Dashboard';
import ListGenericItems from '~/pages/ListGenericItems';
import NewTrack from '~/pages/Tracks/NewTrack';
import ConfigChallenge from '~/pages/AdminExams/ConfigChallenge';
import CreateTrack from '~/pages/AdminExams/CreateTrack';
import ListTracks from '~/pages/AdminExams/ListTracks';
import ListQuestions from '~/pages/AdminExams/ListQuestions';
import Question from '~/pages/AdminExams/Question';
import QuestionsCMS from '~/pages/QuestionsCMS';
import ListTracksNew from '~/pages/Tracks/ListTracks';
import Flashcards from '~/pages/Flashcards';
import EditFlashcards from '~/pages/Flashcards/EditFlashcards';
import Courses from '~/pages/Courses';
import Modules from '~/pages/Courses/Modules';
import NewCourse from '~/pages/Courses/NewCourse';
import ConfigCourse from '~/pages/Courses/ConfigCourse';
import EditLessonModule from '~/pages/Courses/EditLessonModule';
import Activities from '~/pages/Activities';
import ConfigTask from '~/pages/Activities/ConfigTask';
import Financial from '~/pages/Financial';
import FlashcardsDecks from '~/pages/FlashcardsDecks';
import NewFlashcardsDecks from '~/pages/FlashcardsDecks/NewFlashcardsDecks';
import VideoHighlights from '~/pages/VideoHighlights';

import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/reset_password" component={ResetPassword} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/listItems/:modelKey" component={ListGenericItems} isPrivate />

      <Route
        path="/chapter"
        exact
        component={ListChapter}
        isPrivate
        routePermissions={['view_chapter']}
      />
      <Route
        path="/chapter/new"
        component={CreateChapter}
        isPrivate
        routePermissions={['add_chapter']}
      />
      <Route
        path="/chapter/edit/:id"
        component={EditChapter}
        isPrivate
        routePermissions={['change_chapter']}
      />
      <Route
        path="/chapter/preview/:id"
        component={PreviewChapter}
        isPrivate
        routePermissions={['view_chapter']}
      />

      <Route
        path="/challenge"
        exact
        component={ListTracks}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/challenge/new"
        component={ConfigChallenge}
        isPrivate
        routePermissions={['add_track']}
      />
      <Route
        path="/challenge/:id"
        component={ConfigChallenge}
        isPrivate
        routePermissions={['add_track']}
      />

      <Route
        path="/exam"
        exact
        component={ListTracks}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route path="/exam/new" component={CreateTrack} isPrivate routePermissions={['add_track']} />
      <Route
        path="/exam/:id"
        exact
        component={ListQuestions}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/question/:question_id/:action"
        exact
        component={Question}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/question/"
        exact
        component={QuestionsCMS}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/exam/:examId/question/:question_number/:question_id/:action"
        component={Question}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/tracks"
        exact
        component={ListTracksNew}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route path="/tracks/new" component={NewTrack} isPrivate routePermissions={['view_track']} />
      <Route
        path="/tracks/:id"
        exact
        component={NewTrack}
        isPrivate
        routePermissions={['view_track']}
      />
      <Route
        path="/flashcards"
        exact
        component={Flashcards}
        isPrivate
        routePermissions={['view_flashcard']}
      />
      <Route
        path="/flashcards/edit"
        exact
        component={EditFlashcards}
        isPrivate
        routePermissions={['change_flashcard']}
      />
      <Route
        path="/courses"
        exact
        component={Courses}
        isPrivate
        routePermissions={['view_course']}
      />
      <Route
        path="/courses/:id/modules"
        component={Modules}
        isPrivate
        routePermissions={['change_course']}
      />
      <Route
        path="/courses/new"
        exact
        component={NewCourse}
        isPrivate
        routePermissions={['add_course']}
      />
      <Route
        path="/courses/:id"
        exact
        component={ConfigCourse}
        isPrivate
        routePermissions={['change_course']}
      />
      <Route
        path="/courses/:course_id/lesson-module/:id"
        exact
        component={EditLessonModule}
        isPrivate
        routePermissions={['change_courselessontemplate']}
      />
      <Route
        path="/activities"
        exact
        component={Activities}
        isPrivate
        routePermissions={['view_activity']}
      />
      <Route
        path="/create-activity"
        exact
        component={ConfigTask}
        isPrivate
        routePermissions={['add_activity']}
      />
      <Route
        path="/edit-activity/:id"
        exact
        component={ConfigTask}
        isPrivate
        routePermissions={['change_activity']}
      />
      <Route
        path="/financial"
        exact
        component={Financial}
        isPrivate
        routePermissions={['view_activity']}
      />
      <Route
        path="/flashcards-decks"
        exact
        component={FlashcardsDecks}
        isPrivate
        routePermissions={['view_flashcard']}
      />
      <Route
        path="/flashcards-decks/new"
        exact
        component={NewFlashcardsDecks}
        isPrivate
        routePermissions={['add_flashcard']}
      />
      <Route
        path="/flashcards-decks/edit"
        exact
        component={NewFlashcardsDecks}
        isPrivate
        routePermissions={['change_flashcard']}
      />
      <Route
        path="/courses/:course_id/lesson-module/:lesson_module_id/lesson/:lesson_id/video-highlights"
        exact
        component={VideoHighlights}
        isPrivate
      />
    </Switch>
  );
}
