import React from "react";
import clsx from "clsx";

import { Text } from "../Text";

import type { TBreadcrumb, TBreadcrumbItem } from "./Breadcrumb.types";

import ChevronRight from "./assets/chevron-right";

import "./Styles.css";

const CHREVON_RIGHT_SIZE: Record<string, number> = {
  xxs: 12,
  xs: 16,
  sm: 20,
  md: 24,
  lg: 28,
  xl: 32,
  xxl: 36,
  xxxl: 40,
  xxxxl: 44,
  xxxxxl: 52,
  display1: 62,
  display2: 70,
  display3: 78,
  display4: 84,
};

const getChevronSize = (size?: string) => {
  return size ? CHREVON_RIGHT_SIZE[size] : 20;
};

export const Breadcrumb: React.FC<TBreadcrumb> = ({
  items = [],
  size = "md",
  onClick,
  className,
}) => {
  const renderItems = (item: TBreadcrumbItem, index: number) => {
    const isLastItem = index === items.length - 1;

    return (
      <React.Fragment key={item.key || `breadcrumb-${item.label}`}>
        {index !== 0 && <ChevronRight size={getChevronSize(size)} />}
        <Text
          size={size}
          color={isLastItem ? "primary-accent" : "primary-70"}
          weight={isLastItem ? "semibold" : "regular"}
          id={item.id}
          className={clsx(!isLastItem && "breadcrumb-item-DS", item.className)}
          onClick={() => onClick(item.href)}
        >
          {item.label}
        </Text>
      </React.Fragment>
    );
  };

  return (
    <nav className={clsx("breadcrumb-container-DS", className)}>
      {items?.map((item, index) => renderItems(item, index))}
    </nav>
  );
};
