import styled, { css } from 'styled-components';

export const Checkbox = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px;

  ${(props) =>
    props.type &&
    props.type !== 'default' &&
    css`
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    `}

  background: ${(props) => (props.checked ? '#01CFB5' : '#f9f9fa')};
  border: ${(props) => (props.checked ? '2px solid #01CFB5' : '2px solid #ccd2de')};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  ${(props) =>
    !props.disabled &&
    css`
      :hover ${Checkbox} {
        border: ${props.checked ? '2px solid #01CFB5' : '2px solid #00205b'};
        box-shadow: ${props.checked
          ? '0px 0px 0px 6px rgba(1, 207, 181, 0.2)'
          : '0px 0px 0px 6px rgba(0, 32, 91, 0.2)'};
      }
    `}
`;
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
`;

export const Text = styled.label`
  font-size: 16px;
  line-height: 24px;
  line-height: 25px;
  color: #00205b;
  margin-left: 0;
  height: 25px;

  ${(props) =>
    props.addMarginLeft &&
    css`
      margin-left: 16px;
    `}

  ${(props) =>
    props.type &&
    props.type !== 'default' &&
    css`
      font-size: 12px;
      line-height: 16px;
      margin-left: 12px;
    `}
  ${(props) =>
    props.type &&
    props.type === 'large' &&
    css`
      font-size: 16px;
      line-height: 25px;
    `}

  :hover {
    cursor: pointer;
  }
`;
