import React from 'react';
import SettingsIcon from '~/assets/drops-settings.svg';
import EditIcon from '~/assets/drops-edit.svg';
import { Mixpanel } from '~/services/analytics';

import * as S from './styles';

function List({ data }) {
  const handleSettings = (item) => {
    Mixpanel.track("Clicou 'Lista de Decks - Botão de Configuração'", {
      'Nome do Deck': item.title,
      'ID do Deck': item.id,
      'Quantidade de flashcards': item.total_flashcards_count,
    });

    return window.open(`/flashcards-decks/edit?id=${item.id}`, '_blank');
  };

  const handleEdit = (item) => {
    Mixpanel.track("Clicou 'Lista de Decks - Botão de Editar'", {
      'Nome do Deck': item.title,
      'ID do Deck': item.id,
      'Quantidade de flashcards': item.total_flashcards_count,
    });
    return window.open(`flashcards/edit?flashcard_decks=${item?.id}`, '_blank');
  };

  return (
    <S.Wrap>
      {data?.map((item) => {
        return (
          <S.Card>
            <S.Content>
              <S.LeftContent>
                <S.Title>{`${item?.title || ''}`}</S.Title>
                <S.Description>
                  <>
                    {!!item?.total_flashcards_count && (
                      <>
                        <b>{item.total_flashcards_count}</b>
                        {item.total_flashcards_count === 1 ? ' flashcard' : ' flashcards'}
                        {' - '}
                      </>
                    )}
                    <b>ID:</b>
                    {` ${item?.id}`}
                  </>
                </S.Description>
              </S.LeftContent>
              <S.RightContent>
                <S.Button onClick={() => handleSettings(item)}>
                  <img src={SettingsIcon} alt="Settings icon" />
                  Configuração
                </S.Button>
                <S.Button onClick={() => handleEdit(item)}>
                  <img src={EditIcon} alt="Edit icon" />
                  Editar
                </S.Button>
              </S.RightContent>
            </S.Content>
          </S.Card>
        );
      })}
    </S.Wrap>
  );
}

export default List;
