/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch } from 'react-redux';
import history from '~/services/history';
import { ReactComponent as ConfigurationIcon } from '~/assets/configuration-icon.svg';
import { Mixpanel } from '~/services/analytics';
import { saveCourseStatus } from '~/store/modules/courses/actions';

import * as S from './styles';

function CourseItem({ courseName, id, activeStatus }) {
  const dispatch = useDispatch();

  const handleConfigure = () => {
    dispatch(saveCourseStatus(activeStatus));
    Mixpanel.track("Clicou 'Lista de cursos - Botão de Configuração de Curso'", {
      'Nome do curso': courseName,
    });
    window.open(`/courses/${id}`, '_blank');
  };

  const handleEdit = () => {
    Mixpanel.track("Clicou 'Lista de cursos - Botão de Editar o Curso'", {
      'Nome do curso': courseName,
    });
    window.open(`/courses/${id}/modules`, '_blank');
  };

  return (
    <S.Container>
      <S.Title>{courseName}</S.Title>
      <S.ButtonsContainer>
        <S.Button onClick={handleConfigure}>
          <ConfigurationIcon size={20} />
          Configuração
        </S.Button>
        <S.Button onClick={handleEdit}>
          <S.StyledEditIcon />
          Editar
        </S.Button>
      </S.ButtonsContainer>
    </S.Container>
  );
}

export default CourseItem;
