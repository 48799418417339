import React, { useState, useCallback, useEffect } from 'react';
import { format, add, sub } from 'date-fns';

import { CourseAPICMS } from '~/services/apiCallsCMS';
import { useTrackCategory } from '~/hooks-querys/trackCategory';
import { useFilterSpecialitys } from '~/hooks-querys/filters';

import ModalFilter from '~/components/ModalFilter';
import SelectWithPagination from '~/components/SelectWithPagination';
import SelectComponent from '~/components/SelectComponent';

import * as S from './styles';

import SearchIcon from '~/assets/drops-search.svg';

function Filter({ filter, setFilter }) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [enabledTrack, setEnabledTrack] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [categorys, setCategorys] = useState([]);
  const [specialities, setSpecialities] = useState([]);

  const today = format(new Date(), 'yyyy-MM-dd');
  const tomorrow = format(
    add(new Date(), {
      days: 1,
    }),
    'yyyy-MM-dd',
  );
  const sevenDaysAgo = format(
    sub(new Date(), {
      days: 7,
    }),
    'yyyy-MM-dd',
  );
  const aMonthAgo = format(
    sub(new Date(), {
      months: 1,
    }),
    'yyyy-MM-dd',
  );
  const aYearAgo = format(
    sub(new Date(), {
      years: 1,
    }),
    'yyyy-MM-dd',
  );

  const enabledItems = [
    { label: 'Sim', value: true, key: 'sim' },
    { label: 'Não', value: false, key: 'não' },
  ];

  const dataItems = [
    { label: 'Hoje', value: today, key: 1 },
    { label: 'Últimos 7 dias', value: sevenDaysAgo, key: 2 },
    { label: 'Este mês', value: aMonthAgo, key: 3 },
    { label: 'Este ano', value: aYearAgo, key: 4 },
  ];

  const [loading, setLoading] = useState(false);

  const { data: specialitiesData, isLoading: loadingSpecialities } = useFilterSpecialitys({
    main_speciality: true,
    no_page: true,
  });

  const transformOptions = (item) => {
    if (!item.id || !item.name) return;

    return {
      label: item.name,
      key: item.id,
      value: item.id,
      item,
    };
  };

  const { data: trackCategoryData, isLoading: loadingTrackCategory } = useTrackCategory({
    ordering: 'abbreviation',
  });

  useEffect(() => {
    if (!trackCategoryData && !trackCategoryData?.results) return;
    setCategorys((categorys) => [...categorys, ...trackCategoryData.results.map(transformOptions)]);
  }, [trackCategoryData]);

  useEffect(() => {
    if (!specialitiesData) return;
    setSpecialities((specialities) => [...specialities, ...specialitiesData.map(transformOptions)]);
  }, [specialitiesData]);

  const loadCourses = useCallback(async (search, loadedOptions, { page }) => {
    setLoading(true);
    const { data } = await CourseAPICMS.list({
      page,
      search,
      ordering: 'name',
      residency: true,
    });
    setLoading(false);
    return {
      options: data.results.map(transformOptions),
      hasMore: data.next,
      additional: {
        page: page + 1,
      },
    };
  }, []);

  const changeFilter = (value) => {
    setFilter({ ...filter, search: value, page: 1 });
  };

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const selectedProps = {
    width: '100%',
    controlStyle: { marginLeft: 0 },
    singleValueStyle: selectValueStyle,
    placeholderStyle: selectValueStyle,
  };

  return (
    <S.FlexColumn>
      <S.FlexRow>
        <S.FilterInput>
          <img src={SearchIcon} alt="Search icon" />
          <input
            type="text"
            value={filter.search}
            onChange={(e) => changeFilter(e.target.value)}
            placeholder="Pesquisar"
          />
        </S.FilterInput>
        <SelectComponent
          name="residency-degree"
          placeholder={loadingTrackCategory ? 'Carregando...' : 'Categoria de prova'}
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e);
            setFilter({ ...filter, category: e.value, page: 1 });
          }}
          options={categorys}
          {...selectedProps}
        />
        <SelectWithPagination
          name="course"
          height={40}
          width="100%"
          value={selectedCourse}
          onChange={(e) => {
            setSelectedCourse(e);
            setFilter({ ...filter, course: e.value, page: 1 });
          }}
          loadOptions={loadCourses}
          placeholder={loading ? 'Carregando...' : 'Curso'}
        />
      </S.FlexRow>
      <S.FlexRow>
        <SelectComponent
          name="specialities"
          placeholder={loadingTrackCategory ? 'Carregando...' : 'Especialidade'}
          value={selectedSpeciality}
          onChange={(e) => {
            setSelectedSpeciality(e);
            setFilter({ ...filter, speciality: e.value, page: 1 });
          }}
          options={specialities}
          {...selectedProps}
        />
        <SelectComponent
          name="enabled"
          placeholder={loadingTrackCategory ? 'Carregando...' : 'Disponível'}
          value={enabledTrack}
          onChange={(e) => {
            setEnabledTrack(e);
            setFilter({ ...filter, enabled: e.value, page: 1 });
          }}
          options={enabledItems}
          {...selectedProps}
        />
        <SelectComponent
          name="dateSelected"
          placeholder={'Data de criação'}
          value={selectedDate}
          onChange={(e) => {
            setSelectedDate(e);
            setFilter({
              ...filter,
              created_at__gte: e.value,
              created_at__lt: tomorrow,
              page: 1,
            });
          }}
          options={dataItems}
          {...selectedProps}
        />
      </S.FlexRow>
    </S.FlexColumn>
  );
}

export default Filter;
