import { useQuery } from '@tanstack/react-query';
import { LessonHighlightsAPICMS } from '~/services/apiCallsCMS';

const getLessonHighlights = async (id) => {
  try {
    const response = await LessonHighlightsAPICMS.getLessonHighlights(id);
    if (!response && !response.data && !response.data.results) {
      throw new Error('No results found');
    }

    return response.data.results;
  } catch (error) {
    throw error;
  }
};

export default function useGetLessonHighlights(id, options) {
  return useQuery({
    queryKey: ['getLessonHighlights', id],
    queryFn: () => getLessonHighlights(id),
    staleTime: 2 * (60 * 1000), //2 minutes
    ...options?.config,
  });
}
