import React from "react";
import clsx from "clsx";

import type { TextProps } from "./Text.types";
import "./Styles.css";

export const Text = ({
  children,
  as: Component = "div",
  size = "md",
  weight = "regular",
  color = "primary",
  truncate = false,
  decoration,
  styles,
  className,
  ...props
}: TextProps) => {
  const isCustomColor =
    color.startsWith("#") ||
    color.startsWith("rgb") ||
    color.startsWith("hsl") ||
    color === "inherit" ||
    color === "currentColor";

  const style = {
    ...styles,
    "--text-color": isCustomColor ? color : `var(--${color})`,
    textDecoration: decoration,
  } as React.CSSProperties;

  return (
    <Component
      className={clsx(
        "text-DS",
        `text-${size}-DS`,
        `text-${weight}-DS`,
        {
          truncate,
        },
        className
      )}
      style={style}
      {...props}
    >
      {children}
    </Component>
  );
};
