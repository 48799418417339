import React from "react";

const ChevronRight = ({ size = 20 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M13.0942 9.99999L8.08507 4.99167L6.90674 6.17L10.7401 10.0033L6.90674 13.8308L8.08507 15.0092L13.0942 9.99999Z"
      fill="#D1D7E1"
    />
  </svg>
);

export default ChevronRight;
