import { DefaultUsersAPICMS } from '~/services/apiCallsCMS';

export const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
    email: item.email,
    name: item?.name.split(' - ')[0],
    ...(item?.multiplier && { multiplier: item.multiplier }),
    ...(item?.speciality_id && { speciality: item.speciality_id }),
    ...(item?.parentTag && { parentTag: item.parent_tag }),
  };

  return returnValues;
};

const renderOptions = (data, page) => {
  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const loadDefaultUsers = async (search, loadedOptions, { page }) => {
  const { data } = await DefaultUsersAPICMS.getDefaultUsers({
    search,
    page,
  });

  data.results.forEach((partner) => {
    partner.name = `${partner.name} - ${partner.email}`;
  });
  return renderOptions(data, page);
};
