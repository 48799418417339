import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import DatePicker from '~/components/DatePickerComponent';
import RadioButton from '~/components/RadioButton';

import { FIELD_TYPE } from '~/utils/variables';
import { SelectStyle } from '~/pages/_layouts/default/styles';

import * as S from './styles';

function FormField({ formField, setAnswers, answerUpdateTask, isNested }) {
  const getSelectedValue = (value) => {
    if (formField.field.field_type === FIELD_TYPE.RADIO) {
      return value;
    }

    if (formField.field.field_type === FIELD_TYPE.SELECT) {
      const item = formField.list_values?.find((itemList) => itemList.template_url === value);
      return { value: item.id, label: item.value };
    }

    return null;
  };

  const [selectedValue, setSelectedValue] = useState(
    answerUpdateTask ? getSelectedValue(answerUpdateTask.object_id?.toString()) : null,
  );
  const [valueTyped, setValueTyped] = useState(answerUpdateTask ? answerUpdateTask.answer : null);

  const updateAnswer = (value) => {
    setAnswers((prevAnswers) => {
      // Find the index of the existing answer for the given formField.id
      const existingAnswerIndex = prevAnswers.findIndex(
        (answer) => answer.form_field === formField.id,
      );

      if (existingAnswerIndex !== -1) {
        // If the answer exists, create a new array with the updated answer
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex] = {
          ...updatedAnswers[existingAnswerIndex],
          [formField.field.field_type === FIELD_TYPE.SELECT ? 'answer_text' : 'answer_id']: value,
          answer_id: value,
        };
        return updatedAnswers;
      }
      // If the answer doesn't exist, add a new one
      return [
        ...prevAnswers,
        {
          form_field: formField.id,
          [formField.field.field_type === FIELD_TYPE.SELECT ? 'answer_text' : 'answer_id']: value,
        },
      ];
    });
  };

  const handleSelected = (event) => {
    setSelectedValue(event);
    updateAnswer(event.value);
  };

  const handleSelectedRadio = (event) => {
    setSelectedValue(event.target.value.toString());
    updateAnswer(parseInt(event.target.value, 10));
  };

  const handleValueTyped = (event) => {
    setValueTyped(event.target.value);
  };

  useEffect(() => {
    if (valueTyped) {
      setAnswers((prevAnswers) => {
        // Find the index of the existing answer for the given formField.id
        const existingAnswerIndex = prevAnswers.findIndex(
          (answer) => answer.form_field === formField.id,
        );

        if (existingAnswerIndex !== -1) {
          // If the answer exists, create a new array with the updated answer
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[existingAnswerIndex] = {
            ...updatedAnswers[existingAnswerIndex],
            answer_text: valueTyped.toString(),
          };
          return updatedAnswers;
        } else {
          // If the answer doesn't exist, add a new one
          return [...prevAnswers, { form_field: formField.id, answer_text: valueTyped.toString() }];
        }
      });
    }
  }, [valueTyped]);

  const renderInput = (formField) => {
    if (
      formField.field.field_type === FIELD_TYPE.TEXT ||
      formField.field.field_type === FIELD_TYPE.URL ||
      formField.field.field_type === FIELD_TYPE.INTEGER
    ) {
      return (
        <S.InputStyle>
          <input
            id={`form-${formField.field.id}`}
            type={formField.field.field_type === FIELD_TYPE.INTEGER ? 'number' : 'text'}
            placeholder={formField.field.placeholder}
            onChange={handleValueTyped}
            value={valueTyped || ''}
          />
        </S.InputStyle>
      );
    }

    if (
      formField.field.field_type === FIELD_TYPE.DATE ||
      formField.field.field_type === FIELD_TYPE.TIME ||
      formField.field.field_type === FIELD_TYPE.DATETIME
    ) {
      return (
        <S.RowContainerInputs>
          <DatePicker
            id={`form-${formField.field.id}`}
            label={formField.field.placeholder}
            time={formField.field.field_type !== FIELD_TYPE.DATE}
            value={valueTyped}
            handleChange={(event) => setValueTyped(event)}
          />
        </S.RowContainerInputs>
      );
    }

    if (formField.field.field_type === FIELD_TYPE.RADIO) {
      return (
        <S.RadioContainer id={`form-${formField.field.id}`}>
          {formField.static_values.map((staticValue) => {
            return (
              <>
                <RadioButton
                  id={`form-${formField.field.id}`}
                  text={staticValue.value}
                  value={staticValue.id.toString()}
                  control={selectedValue}
                  onChange={(event) => handleSelectedRadio(event)}
                />
                {staticValue.nested_field.length > 0 &&
                  staticValue.nested_field.map((formFieldItem) => {
                    return (
                      <S.RowNested>
                        <FormField
                          formField={formFieldItem}
                          setAnswers={setAnswers}
                          isNested="true"
                        />
                      </S.RowNested>
                    );
                  })}
              </>
            );
          })}
        </S.RadioContainer>
      );
    }

    if (formField.field.field_type === FIELD_TYPE.SELECT) {
      const options = formField.list_values.map((option) => ({
        value: option.template_url,
        label: option.name,
      }));

      return (
        <S.SelectContainer>
          <Select
            id={`form-${formField.field.id}`}
            placeholder={formField.field.placeholder}
            styles={SelectStyle}
            options={options}
            onChange={handleSelected}
            value={selectedValue}
          />
        </S.SelectContainer>
      );
    }
  };
  return (
    <>
      {isNested ? (
        renderInput(formField)
      ) : (
        <S.RowContainer>
          <S.Rows>{formField.field.label}</S.Rows>
          {formField.field.subtitle && <S.RowMinor>{formField.field.subtitle}</S.RowMinor>}
          {renderInput(formField)}
        </S.RowContainer>
      )}
    </>
  );
}

export default FormField;
