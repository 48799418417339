import React from 'react';
import { BiPlusCircle } from 'react-icons/bi';

import * as S from './styles';

function Icons({ disabled, iconName }) {
  const ReturnIcon = (icon) => {
    switch (icon) {
      case 'add':
        return <BiPlusCircle size={20} color={disabled ? '#8B97BD' : '#077859'} />;
      case 'delete':
        return <S.StyledDeleteIcon disabled={disabled} />;
      case 'edit':
        return <S.StyledEditIcon disabled={disabled} />;
      case 'view':
        return <S.StyledViewIcon disabled={disabled} />;
      case 'search':
        return <S.StyledSearchIcon disabled={disabled} />;
      case 'configuration':
        return <S.StyledConfigurationIcon disabled={disabled} />;
      case 'highlight':
        return <S.StyledHighlightIcon disabled={disabled} />;
      default:
        return <></>;
    }
  };

  return <>{ReturnIcon(iconName)}</>;
}

export default Icons;
