import React from 'react';
import history from '~/services/history';
import RadioGroup from '../components/RadioGroup';
import InputMUI from '~/components/InputMUI';
import { Mixpanel } from '~/services/analytics';

export const transformOptions = (value) => {
  if (!value.id || !value.name) return;

  return {
    label: value.name,
    key: value.id,
    value: `${value.id}`,
  };
};

const handleChange = (event, formProp, setFormsData) => {
  return setFormsData((formsData) => {
    return { ...formsData, [formProp]: event.target.value };
  });
};

export const handleChangeData = (event, formProp, setFormsData) => {
  if (isNaN(new Date(event).getTime())) return;
  return setFormsData((formsData) => {
    return { ...formsData, [formProp]: new Date(event).toISOString() };
  });
};

const extractOnlyId = (options) => {
  let onlyId = [];
  if (options && options.length > 0) {
    options.forEach((option) => {
      onlyId = [...onlyId, option.key];
    });
  }

  return onlyId;
};

export const handleChangeFeatures = (options, setSelectedFeatures, setFormsData) => {
  setSelectedFeatures(options);
  return setFormsData((formsData) => {
    return { ...formsData, course_features: extractOnlyId(options) };
  });
};

export const handleChangeTrackTypes = (options, setSelectedTrackTypes, setFormsData) => {
  setSelectedTrackTypes(options);

  return setFormsData((formsData) => {
    return { ...formsData, track_type_available: extractOnlyId(options) };
  });
};

const handleBlur = (propName, value, setFormsData) => {
  return setFormsData((formsData) => {
    return { ...formsData, [propName]: value };
  });
};

export const filterFeatures = async (search, features) => {
  if (!search) {
    return features;
  }
  return features.filter((item) => search.includes(item.label));
};

export const confirmRemove = (id, setShowModalConfirmation, setIdDelete) => {
  setShowModalConfirmation(true);
  setIdDelete(id);
};

export const handleBack = () => {
  history.push('/courses');
};

export const RenderRadioGroup = (data, prop, formsData, setFormsData) => {
  return (
    <>
      {formsData ? (
        <RadioGroup
          data={data}
          handleRadio={handleChange}
          radioValue={formsData[prop] || ''}
          formProp={prop}
          setFormsData={setFormsData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const RenderInput = (placeholder, prop, grayBg, isNumber, formsData, setFormsData) => {
  let extraData;
  if (isNumber) {
    extraData = {
      type: 'number',
      inputProps: {
        min: 0,
        max: 100,
      },
    };
  }
  return (
    <>
      {formsData ? (
        <InputMUI
          label=""
          value={formsData[prop] || ''}
          placeholder={placeholder}
          id={prop}
          handleBlur={handleBlur}
          size="medium"
          inputCustomStyles={{ background: grayBg ? '#F0F2F5' : '#fff' }}
          setFormsData={setFormsData}
          {...extraData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const sendMixpanelTracks = (
  trackTitle,
  data,
  features,
  selectedTrackTypes,
  residencyDegree,
  contentType,
) => {
  const featuresNameList =
    data?.course_features &&
    data?.course_features.length > 0 &&
    data?.course_features.map((id) => {
      return features.find((element) => element.value == id).label;
    });

  const trackTypesNames =
    selectedTrackTypes &&
    selectedTrackTypes.length > 0 &&
    selectedTrackTypes.map((trackType) => trackType.label);

  const residencyDegreeLabel =
    residencyDegree &&
    data?.residency_degree &&
    residencyDegree.find((element) => element.value == data.residency_degree).label;

  const lessonTemplate = contentType?.map((item) => item.name);

  Mixpanel.track(`${trackTitle}`, {
    'Nome do curso': data.name,
    'Acesso ao Planner': data?.mentoring_access,
    'Porcentagem para certificação': data?.certificate_minimum_percentage_required,
    'Features do cursos': data?.course_features ? featuresNameList : [],
    'Nível de Residência': data?.residency_degree ? residencyDegreeLabel : '',
    'URL Checkout': data?.url_checkout,
    'Disponibilizar trilhas': data?.track_available,
    'Tipos de trilhas': data?.track_type_available ? trackTypesNames : [],
    'Disponibilizar questões R3': data?.residency_tree,
    'Disponibilizar apostilas': data?.subject_available,
    'Início da disponibilidade de apostilas':
      data?.subject_start_availability &&
      data?.subject_start_availability.split('-').reverse().join('-'),
    'Disponibilizar aulas': data?.lesson_available,
    'Início da disponibilidade de aulas':
      data?.lesson_start_availability &&
      data?.lesson_start_availability.split('-').reverse().join('-'),
    'Padrão de objetos de aula': lessonTemplate,
  });
};

export const hasHttpOrHttps = (url) => {
  return /^https?:\/\//i.test(url);
};
