import { Tag } from '~/services';
import { TAG_TYPE } from '~/utils/variables';

const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
  };

  if (item.parent_tag) {
    returnValues.parentTag = item.parent_tag;
    returnValues.label = `${item.new_residency_degree_abbreviation} - ${item.verbose}`;
  }

  return returnValues;
};

const renderOptions = (data, page) => {
  const customOptions =
    page === 1
      ? [{ label: 'Sem tag', key: -1, value: -1 }, ...data.results.map(transformOptions)]
      : data.results.map(transformOptions);

  return {
    options: customOptions,
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const loadCfas = async (search, loadedOptions, { page }, { parent_tag }) => {
  const { data } = await Tag.list({
    page,
    search,
    tag_type: TAG_TYPE.CFA,
    ...(parent_tag && { parent_tag: parent_tag }),
  });

  return renderOptions(data, page);
};
