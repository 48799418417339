import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const WrapContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  max-width: 1388px;
`;

export const ContainerNewTrack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  align-self: stretch;
`;

export const StepContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 56px;
  border-top: 1px solid #e5e8ee;
`;

export const StepText = styled.span`
  max-width: 350px;
  font-style: normal;
  font-weight: 600;
  font-size: 22.8px;
  line-height: 30px;
  color: #00205b;
`;

export const StepOder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #01c3aa;
  color: #ffffff;
  border-radius: 1000px;
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 36px;
  color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 21px;
  margin: 16px 0px;
  padding-top: 20px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const RowButtonContainer = styled(RowContainer)`
  justify-content: space-between;
`;

export const RowContainerInputs = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
`;

export const Rows = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin-bottom: 20px;

  label {
    width: 100%;
  }
`;

export const RowsText = styled(Rows)`
  margin-bottom: 0px;
`;

export const RowMinor = styled.div`
  margin-top: -15px;
  margin-bottom: 20px;
  color: #4f5154;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ContainerInstitution = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 544px;
`;

export const FooterTrack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 64px);
  margin: 0 32px;
  gap: 24px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #01cfb5;
  }
`;

export const InputStyle = styled.div`
  max-width: 544px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '48px')};
  border: 3px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :disabled {
    :hover {
      border: 3px solid transparent;
      background: red;
    }
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;
  :hover {
    background: #e5faf7;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 24px;
  align-self: stretch;
`;

export const SelectContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: 544px;

  > div {
    width: 100%;
  }
`;

export const SelectContainerColumn = styled(SelectContainer)`
  flex-direction: column;
  gap: 4px;
`;

export const Button = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0caa87;
  height: 40px;
  width: 200px;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #019d8a;
    cursor: default;
  }
`;

export const TipText = styled.div`
  color: #3d5582;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
