import React from 'react';
import { saveAs } from 'file-saver';
import Axios from 'axios';

import { NextAPICMS } from '~/services/apiCallsCMS';

export function downloadPDF(fileURL, fileName, is_csv) {
  fetch(fileURL)
    .then((res) => res.blob())
    .then((blob) => saveAs(blob, is_csv ? `${fileName}.csv` : `${fileName}.pdf`));
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function changeWindowSize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
}

export async function defaultLoadMore({
  next,
  setNext,
  items,
  setItems,
  isMentoring,
  loading,
  setLoading,
}) {
  if (!next || !Array.isArray(items) || items.length === 0 || loading) return;
  const source = Axios.CancelToken.source();
  if (setLoading) {
    setLoading(true);
  }
  NextAPICMS.get(next, source.token)
    .then((result) => {
      setNext(result.data.next);
      if (result.data.results) {
        setItems([...items, ...result.data.results]);
      }
    })
    .catch(() => {
      setNext(false);
    })
    .finally(() => {
      if (setLoading) {
        setLoading(false);
      }
    });
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

//Retorna se 2 objetos são iguais
export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

//retorna se Array2 está contido em Array1
export const checkIfArrayContainsAnother = (Array1, Array2) => {
  return Array2.every((el) => {
    return Array1.includes(el);
  });
};

export const getSpecialityColor = (speciality) => {
  switch (speciality) {
    case 'Clínica Médica':
      return 'var(--color-CM)';
    case 'Cirurgia Geral':
      return 'var(--color-CIR)';
    case 'Pediatria':
      return 'var(--color-PED)';
    case 'Medicina Preventiva e Social':
      return 'var(--color-PREV)';
    case 'Ginecologia e Obstetrícia':
      return 'var(--color-GO)';
    default:
      return 'var(--color-SEM-ESP)';
  }
};

//retorna se Array2 de objetos está contido em Array1 de objetos
export const checkIfArrayOfObjectsContainsAnother = (Array1, Array2) => {
  return Array2.every((el1) => {
    return Array1.some((el2) => {
      return deepEqual(el1, el2);
    });
  });
};

export function convertDate(date) {
  if (!date) return '';
  const shortDate = date.split('T')[0];
  const parts = shortDate.split('-');
  return `${parts[2]}/${parts[1]}/${parts[0]}`;
}

export function limitText(text, limit) {
  if (text.length <= limit) {
    return text;
  } else {
    return text.slice(0, limit) + '...';
  }
}

export const removeHtmlTags = (html) => {
  return html.replace(/(<([^>]+)>)/gi, '');
};

export const getFlashcardStatus = (status_error, enabled) => {
  if (status_error) return 'Reportado';
  if (enabled) return 'Publicado';
  return 'Pendente';
};

export const groupArrayByProp = (array, property) => {
  return array.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

export const convertStringToBoolean = (value) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

export const objectIsEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const extractOnlyId = (options) => {
  let onlyId = [];
  if (options && options.length > 0) {
    options.forEach((option) => {
      onlyId = [...onlyId, option.key];
    });
  }

  return onlyId;
};

export const numericPrice = (stringNumber) => {
  // Remove any non-numeric characters, except for commas and periods
  let stringOnlyNumbers = stringNumber.replace(/[^0-9]/g, '');

  // Convert input to a number and format as currency
  const numericValue = parseFloat(stringOnlyNumbers) / 100; // Convert to a number with two decimal places

  return numericValue;
};

export const formatPrice = (stringNumber, withoutSymbol) => {
  const numericValue = numericPrice(stringNumber);
  let params;

  if (withoutSymbol) {
    params = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  } else {
    params = {
      style: 'currency',
      currency: 'BRL',
    };
  }

  const formattedValue = numericValue.toLocaleString('pt-BR', params);

  return formattedValue;
};

const zeroPad = (num, places) => String(num).padStart(places, '0');

export function secondsToHms(d, withSeparator) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return withSeparator
    ? `${zeroPad(h, 2)}:${zeroPad(m, 2)}:${zeroPad(s, 2)}`
    : `${zeroPad(h, 2)}h${zeroPad(m, 2)}m${zeroPad(s, 2)}s`;
}

export function timeToSeconds(t) {
  const [h, m, s] = t.split(':');
  return Number(h) * 3600 + Number(m) * 60 + Number(s);
}
