/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Chip } from '@material-ui/core';
import { Ring } from 'react-spinners-css';
import { toast } from 'react-toastify';
import Axios from 'axios';

import history from '~/services/history';
import {
  TrackAPICMS,
  CourseAPICMS,
  TrackChallengePdfAPICMS,
  TrackRankingAPICMS,
} from '~/services/apiCallsCMS';

import SearchIcon from '~/assets/drops-search.svg';
import DropsPrevious from '~/assets/dropsPrevious';
import DropsNext from '~/assets/dropsNext';

import ButtonWithPopper from '~/components/ButtonWithPopper';
import Loader from '~/components/Loader';
import CardComponent from '~/components/CardComponent';
import SelectComponent from '~/components/SelectComponent';
import ProgressBar from '~/components/ProgressBar';
import ModalScreen from '~/components/ModalScreen';
import Button from '~/components/ButtonComponent';
import EmptyContent from '~/components/EmptyContent';

import { useTrackCategory } from '~/hooks-querys/trackCategory';
import { useBackButton } from '~/contexts/backButton';

import { downloadPDF } from '~/utils/util';
import * as S from './styles';

export default function Challenges({ statusTab }) {
  const { setBackButtonContext } = useBackButton();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [challenges, setChallenges] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [order, setOrder] = useState(null);
  const search = useSelector((state) => state.search.searchTermExams);
  const [loading, setLoading] = useState(true);
  const [loadingPdfComment, setLoadingPdfComment] = useState(false);
  const [loadingPdfCommentBlind, setLoadingPdfCommentBlind] = useState(false);
  const [loadingPdfNoComment, setLoadingPdfNoComment] = useState(false);
  const [loadingRanking, setLoadingRanking] = useState(false);
  const [loadingTagIa, setLoadingTagIa] = useState(false);
  const [page, setPage] = useState(1);
  const [previous, setPrevious] = useState(false);
  const [next, setNext] = useState(false);
  const [searchTerm, setSearchTerm] = useState(search);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [enabledExam, setEnabledExam] = useState();
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [idExam, setIdExam] = useState();

  const itemsOrder = [
    {
      label: 'Mais recentes',
      value: '-created_at',
      key: '-created_at',
    },
    { label: 'Por ano da prova', value: '-year', key: '-year' },
    { label: 'Por nome', value: 'name', key: 'name' },
  ];

  const { data: trackCategoryData, isLoading: loadingTrackCategory } = useTrackCategory({
    ordering: 'abbreviation',
  });

  const transformOptions = (value) => {
    if (!value.id || !value.name) return;

    return {
      label: value.name,
      key: value.abbreviation,
      value: value.id,
    };
  };

  const loadCourses = useCallback(async (cancelToken) => {
    const { data } = await CourseAPICMS.list({ no_page: true, ordering: 'name' }, cancelToken);
    setCourses(data);
  }, []);

  useEffect(() => {
    if (!trackCategoryData && !trackCategoryData?.results) return;
    setCategorys((categorys) => [...categorys, ...trackCategoryData.results.map(transformOptions)]);
  }, [trackCategoryData]);

  const loadChallenges = async (cancelToken) => {
    setLoading(true);
    const { data } = await TrackAPICMS.list(
      {
        search: searchTerm,
        ordering: order?.value ? order?.value : '-created_at',
        course: selectedCourse?.key,
        track_category: selectedCategory?.value,
        track_type__in: 'b,g',
        enabled: statusTab === 'published',
        page,
      },
      cancelToken,
    ).catch((error) => console.log(`ERRO ${error}`));

    if (data && data.results) {
      setChallenges(data.results);
      setNext(data.next);
      setPrevious(data.previous);
    }
    setLoading(false);
  };

  function handlePrevious() {
    setPage(page - 1);
  }

  useEffect(() => {
    const source = Axios.CancelToken.source();

    loadCourses(source.token);
    loadChallenges(source.token);

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (page > 1) {
      const source = Axios.CancelToken.source();
      loadChallenges(source.token);
    }
  }, [page]);

  function handleNext() {
    setPage(page + 1);
  }

  const handleFilter = () => {
    if (loading && !selectedCourse) {
      return;
    }

    const source = Axios.CancelToken.source();
    loadChallenges(source.token);
  };

  function handleOrder(orderSelected) {
    setOrder(orderSelected);
  }

  const hasPendencies = (question) => {
    if (question.questions_count > question.question_with_comments) return true;
    if (question.questions_count > question.questions_with_tags) return true;
    if (question.questions_count > question.questions_with_content) return true;
    if (question.question_objective_count > question.questions_with_correct_answer) return true;
    return false;
  };

  const handleEnable = (enabled, id, status) => {
    if (status === 'complete') {
      setEnabledExam(enabled);
      setOpenPublishModal(true);
      setIdExam(id);
    } else {
      toast.error('Esta prova está pendente. Termine de preencher para poder publicá-la!');
    }
  };
  const handlePublish = () => {
    TrackAPICMS.update(idExam, { enabled: !enabledExam })
      .then(() => {
        toast.success(`${enabledExam ? 'Despublicado' : 'Publicado'} com sucesso`);

        const source = Axios.CancelToken.source();
        loadChallenges(source.token);
      })
      .catch(() => {
        toast.error(`Não foi possível ${enabledExam ? 'despublicar' : 'publicar'}`);
      })
      .finally(() => {
        setOpenPublishModal(false);
      });
  };

  function handleTrackCategory(trackCategory) {
    setSelectedCategory(trackCategory);
  }

  function handleShowChallenge(challenge) {
    setBackButtonContext({
      path: '/challenge',
      name: 'voltar para Simulados',
    });
    history.push({
      pathname: `/exam/${challenge.id}`,
      state: {
        name: 'voltar para Simulados',
        backTo: '/challenge',
      },
    });
  }

  const handleClear = () => {
    setSelectedCategory(null);
    setSearchTerm('');
    setOrder(null);
  };

  const handleOpenPdf = (trackId, isCmky, isBlind) => {
    if (isCmky && !isBlind) setLoadingPdfComment(true);
    else if (isCmky && isBlind) setLoadingPdfCommentBlind(true);
    else setLoadingPdfNoComment(true);

    const source = Axios.CancelToken.source();
    TrackAPICMS.generateInternalPdf(trackId, isCmky, isBlind, source.token)
      .then(() => {
        TrackChallengePdfAPICMS.checkPDF(trackId, isCmky)
          .then(({ data }) => {
            if (data.results[0].pdf_file) {
              downloadPDF(
                data.results[0].pdf_file,
                data.results[0].pdf_file.split('no_institution/')[1].split('.pdf')[0],
              );
            }
            setLoadingPdfComment(false);
            setLoadingPdfCommentBlind(false);
            setLoadingPdfNoComment(false);
          })
          .catch(() => {
            setLoadingPdfComment(false);
            setLoadingPdfCommentBlind(false);
            setLoadingPdfNoComment(false);
            toast.error('Erro ao gerar o PDF.');
          });
      })
      .catch(() => {
        setLoadingPdfComment(false);
        setLoadingPdfCommentBlind(false);
        setLoadingPdfNoComment(false);
        toast.error('Erro ao gerar o PDF.');
      });
  };

  const handleGenerateTagIA = (trackId) => {
    setLoadingTagIa(true);
    const source = Axios.CancelToken.source();
    TrackAPICMS.suggestTagIA(trackId, source.token)
      .then(() => {
        toast.success(
          'A sugestão foi iniciada com sucesso. Em breve, a sugestão de tags para as questões será concluída.',
        );
        setLoadingTagIa(false);
      })
      .catch(() => {
        toast.error('Erro ao iniciar sugestão de tags.');
      });
  };

  const handleRanking = (trackId) => {
    if (loadingRanking) return;
    setLoadingRanking(true);

    const source = Axios.CancelToken.source();
    TrackAPICMS.generateRanking(trackId, source.token)
      .then(() => {
        TrackRankingAPICMS.checkRanking(trackId)
          .then(({ data }) => {
            if (data.results[0].csv_file) {
              downloadPDF(
                data.results[0].csv_file,
                data.results[0].csv_file.split('no_institution/')[1].split('.csv')[0],
                true,
              );
            }
            setLoadingRanking(false);
          })
          .catch(() => {
            setLoadingRanking(true);
            toast.error('Erro ao gerar o ranking.');
          });
      })
      .catch((error) => {
        setLoadingRanking(true);
        toast.error('Erro ao gerar o ranking.');
      });
  };

  function renderItem(item) {
    const infos = [
      {
        label: 'Enunciadas',
        value: item.questions_with_content,
      },
      {
        label: 'Tagueadas',
        value: item.questions_with_tags,
      },
      {
        label: 'Comentadas',
        value: item.question_with_comments,
      },
      item.question_objective_count > 0 && {
        label: 'Alt. correta',
        value: item.questions_with_correct_answer,
      },
      {
        label: 'Videos',
        value: item.questions_with_video,
      },
    ].filter(Boolean);

    let status;

    if (item.questions_count !== 0 && !hasPendencies(item)) {
      status = 'complete';
    } else {
      status = 'pending';
    }

    function calcPercentage(value) {
      if (item.questions_count === 0) {
        return 0;
      }
      return Math.floor((100 * value) / item.questions_count);
    }

    return (
      <CardComponent
        type="default"
        background="#fff"
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: 20,
          borderRadius: '8px',
        }}
        key={`${item.id}${item.name}`}
      >
        <S.CardContent>
          <S.HeaderContainer>
            <S.HeaderInformationsContainer>
              <S.HeaderInformations>
                <S.ItemTitle>{item.name}</S.ItemTitle>
                {item.residency_degree && <Chip>{item.residency_degree}</Chip>}
              </S.HeaderInformations>
              <S.ItemSubtitle>
                <strong>{item.questions_count}</strong>{' '}
                {item.questions_count > 1 ? 'questões' : 'questão'}
              </S.ItemSubtitle>
            </S.HeaderInformationsContainer>
            <S.ButtonsContainer>
              <S.FillButton onClick={() => history.push(`/challenge/${item.id}`)}>
                {' '}
                <S.StyledConfigurationIcon /> Configuração{' '}
              </S.FillButton>
              <S.FillButton onClick={() => handleShowChallenge(item)}>
                <S.StyledEditIcon /> Editar
              </S.FillButton>
              <S.ContainerSwitch>
                <S.SwitchButton
                  checked={item.enabled}
                  onClick={() => handleEnable(item.enabled, item.id, status)}
                >
                  <S.CircleButton checked={item.enabled} />
                </S.SwitchButton>
                <S.SwitchLabel>Publicado</S.SwitchLabel>
              </S.ContainerSwitch>
              <ButtonWithPopper>
                <S.ButtonPopper onClick={() => handleOpenPdf(item.id, true, false)}>
                  PDF para impressão
                  {loadingPdfComment && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>

                <S.ButtonPopper onClick={() => handleOpenPdf(item.id, true, true)}>
                  PDF para impressão - fonte maior
                  {loadingPdfCommentBlind && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>

                <S.ButtonPopper onClick={() => handleOpenPdf(item.id, false)}>
                  PDF com comentário
                  {loadingPdfNoComment && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>

                <S.ButtonPopper onClick={() => handleRanking(item.id)}>
                  Gerar Ranking
                  {loadingRanking && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>
                <S.ButtonPopper onClick={() => handleGenerateTagIA(item.id)}>
                  Gerar tags via IA
                  {loadingTagIa && <Ring size={20} color="#019d8a" />}
                </S.ButtonPopper>
              </ButtonWithPopper>
            </S.ButtonsContainer>
          </S.HeaderContainer>
          <S.FooterContainer>
            <S.ProgressBarContainer style={{ flexWrap: 'wrap' }}>
              {infos.map((info, index) => {
                const complete = item.questions_count !== 0 && info.value === item.questions_count;
                return (
                  <S.CardProgress key={index}>
                    <S.ChipCount complete={complete}>{info.value}</S.ChipCount>
                    <S.ViewCol style={{ gap: 6 }}>
                      <S.ItemSubtitle>
                        {info.label} <strong>{calcPercentage(info.value)}%</strong>
                      </S.ItemSubtitle>
                      <ProgressBar
                        unfilledColor={complete ? '#D8F4E6' : '#FCDBDF'}
                        color={complete ? '#0CBA66' : '#EE1F38'}
                        percentage={calcPercentage(info.value)}
                        height={4}
                        style={{ minWidth: '140px' }}
                      />
                    </S.ViewCol>
                  </S.CardProgress>
                );
              })}
            </S.ProgressBarContainer>
          </S.FooterContainer>
        </S.CardContent>
      </CardComponent>
    );
  }

  const selectValueStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const selectedProps = {
    width: '100%',
    controlStyle: { marginLeft: 0 },
    singleValueStyle: selectValueStyle,
    placeholderStyle: selectValueStyle,
    valueContainerStyle: { flexWrap: 'nowrap' },
    containerStyle: { minWidth: '140px' },
  };

  return (
    <>
      <S.FilterContainer>
        <S.FilterBox flexBasis={'19%'}>
          <S.Filter>
            <img src={SearchIcon} alt="Search icon" />
            <input
              className="textInput"
              name="search"
              type="text"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              placeholder="Pesquisar"
            />
          </S.Filter>
        </S.FilterBox>
        <S.FilterBox flexBasis={'19%'}>
          <SelectComponent
            name="course"
            isSearchable
            value={selectedCourse}
            onChange={setSelectedCourse}
            options={courses.map((c) => {
              return { key: c.id, value: c.id, label: c.name };
            })}
            noOptionsMessage={() => 'Nenhum encontrado'}
            placeholder="Curso"
            {...selectedProps}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'19%'}>
          <SelectComponent
            name="residency-degree"
            placeholder={loadingTrackCategory ? 'Carregando...' : 'Nível de residência'}
            value={selectedCategory}
            onChange={handleTrackCategory}
            options={categorys}
            {...selectedProps}
          />
        </S.FilterBox>
        <S.FilterBox flexBasis={'19%'}>
          <SelectComponent
            name="order"
            value={order}
            placeholder="Ordenar por"
            defaultValue={order}
            onChange={handleOrder}
            options={itemsOrder}
            {...selectedProps}
          />
        </S.FilterBox>
        <S.ButtonsContainer>
          <S.ClearButton onClick={handleClear}>Limpar</S.ClearButton>
          <S.ButtonFilter onClick={handleFilter}>Filtrar</S.ButtonFilter>
        </S.ButtonsContainer>
      </S.FilterContainer>
      <S.Container>
        <S.ListExams>
          <S.MainContainer>
            {loading ? (
              <Loader />
            ) : (
              <>
                {!loading && challenges.length === 0 && (
                  <EmptyContent
                    title="Utilize o filtro para encontrar um simulado"
                    subtitle="Selecione os critérios acima e clique em ”Filtrar”"
                  />
                )}
                {challenges.map((challenge) => renderItem(challenge))}
                {!loading && challenges.length > 0 && (
                  <S.ContainerPagination>
                    <span>
                      Mostrando <strong>{challenges.length}</strong> itens
                    </span>
                    <S.Pagination>
                      <S.ButtonPagination onClick={() => handlePrevious()} disabled={!previous}>
                        <DropsPrevious /> Anterior
                      </S.ButtonPagination>
                      <S.ButtonPagination onClick={() => handleNext()} disabled={!next}>
                        Próximo <DropsNext />
                      </S.ButtonPagination>
                    </S.Pagination>
                    <span>
                      <strong>{challenges.length}</strong> trilhas
                    </span>
                  </S.ContainerPagination>
                )}
              </>
            )}
          </S.MainContainer>
        </S.ListExams>
      </S.Container>
      <ModalScreen
        show={openPublishModal}
        close={() => setOpenPublishModal(false)}
        name={`Você tem certeza que quer ${enabledExam ? 'despublicar' : 'publicar'}?`}
      >
        <S.GroupButtonModal>
          <Button
            type="important"
            text="Não"
            primaryColor="#01cfb5"
            textColor="#fff"
            onClick={() => setOpenPublishModal(false)}
          />
          <Button
            type="important"
            text="Sim"
            primaryColor="#01cfb5"
            textColor="#fff"
            onClick={() => handlePublish()}
          />
        </S.GroupButtonModal>
      </ModalScreen>
    </>
  );
}
