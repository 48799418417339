import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(24, 26, 30, 0.16), 0px 4px 8px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 382px;
`;

export const ModalTitle = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #2a2c30;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
